.product-search .treeBox {
  min-height: 250px;
  border: 1px solid #eee;
  border-left: solid #66cc23 1px;
  padding: 10px; }

@media (min-width: 768px) and (max-width: 1024px) {
  .product-search {
    margin-left: -14px !important;
    margin-right: -14px !important; } }

@media (min-width: 481px) and (max-width: 748px) {
  .product-search {
    margin-left: -14px !important;
    margin-right: -14px !important; } }

@media screen and (max-width: 480px) {
  .product-search {
    margin-left: -14px !important;
    margin-right: -14px !important; } }

.product-search .search-box .input {
  width: 100%; }

.product-search .search-box .results {
  width: 100% !important; }

.product-search .show-specification {
  margin-top: 10px; }
  .product-search .show-specification .more-specifications span {
    padding-right: 10px; }

.product-search .more-button {
  margin-top: 10px;
  float: right !important; }

.product-search .results {
  width: 60% !important;
  z-index: 1100 !important; }

.product-search .node {
  padding-left: 10px;
  padding-bottom: 10px; }

.product-search .m-node .is-active {
  font-weight: bold; }

.product-search .m-node .secondary-group {
  font-weight: bold; }

.product-search .m-node .tertiary-group {
  font-size: 0.9em; }

.product-search .m-tree > .m-node > .inner {
  display: none; }

.product-search .m-node .caret-right:before {
  content: "\002B"; }

.product-search .m-node .caret-down:before {
  content: "\2212"; }

.product-search .relevant-product-count {
  padding-top: 10px; }

.product-search .filter-label-text {
  padding-right: 10px; }

.product-search .filter-label {
  margin-right: 10px;
  margin: 10px 8px 0px 0px; }

.product-search .default-container {
  margin: 10px auto; }
  .product-search .default-container .default-grid-column {
    cursor: pointer; }
    .product-search .default-container .default-grid-column .category-card-class:hover {
      font-weight: bold;
      background: #eee; }
  .product-search .default-container .default-custom-column {
    border: 1px solid #eee;
    border-radius: 3px;
    box-shadow: 0 0 10px #eee;
    margin: 5px; }

.product-search .filter-container {
  margin-top: 10px;
  margin-bottom: 10px; }
  .product-search .filter-container .filter-cancel-icon {
    float: right;
    margin-left: 10px;
    margin-right: -10px; }

.product-search .product-card .product-header {
  max-width: calc(100% - 100px);
  word-wrap: break-word;
  margin-top: -32px !important;
  font-size: 0.8em; }
  .product-search .product-card .product-header .product-header-stock {
    font-size: 1.2em;
    padding-right: 5px;
    color: #66cc23; }
  .product-search .product-card .product-header .card-meta {
    font-weight: normal;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.4); }

@media screen and (max-width: 480px) {
  .product-search .product-card .card-description .product-info-grid {
    padding-right: 0px; } }

.product-search .product-card .card-description .product-info-grid .add-card-column {
  margin-top: 10px;
  border-left: 1px solid #eee; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .product-search .product-card .card-description .product-info-grid .add-card-column {
      margin: 14px auto; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .product-search .product-card .card-description .product-info-grid .add-card-column {
      margin: 14px auto; } }
  @media screen and (max-width: 480px) {
    .product-search .product-card .card-description .product-info-grid .add-card-column {
      margin: 14px auto; } }
  .product-search .product-card .card-description .product-info-grid .add-card-column .cart-button {
    margin-top: 44px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .product-search .product-card .card-description .product-info-grid .add-card-column .cart-button {
        margin-top: 27px; } }
  .product-search .product-card .card-description .product-info-grid .add-card-column .quantity-form {
    margin-top: 5px; }
    .product-search .product-card .card-description .product-info-grid .add-card-column .quantity-form .quantity-label {
      margin-top: 6px;
      text-align: center;
      font-weight: bold; }
    .product-search .product-card .card-description .product-info-grid .add-card-column .quantity-form .quantity-width {
      margin-top: 5px;
      width: 100%; }
  .product-search .product-card .card-description .product-info-grid .add-card-column .box-saving-text {
    color: #66cc23;
    font-weight: bold; }

.product-search .m-tree {
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 0 0 10px #eee;
  border-left: 5px solid #66cc23;
  padding: 5px; }
  .product-search .m-tree .m-node {
    font-size: 17px; }
    .product-search .m-tree .m-node .inner:hover {
      background: #66cc23;
      color: #fff;
      box-shadow: 0 0 1px #999; }
    .product-search .m-tree .m-node .inner .is-active {
      color: #66cc23; }

.product-search .side-nav-container {
  margin-top: 10px; }

.product-search .search-placeHolder {
  z-index: 100 !important; }

@media screen and (max-width: 480px) {
  .product-search .specification-labels {
    display: flow-root; } }
