.svg-button {
  color: #fff;
  border: 1px solid #3eb5ea;
  background-color: #3eb5ea;
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.svg-button:hover {
  border: 1px solid #2980b9;
  background-color: #2980b9; }
