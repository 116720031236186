.logoImage {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.bomImage {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.error input {
  border-color: #cc181e !important; }

.error {
  border-color: #cc181e !important; }

.select-error {
  border-color: #cc181e !important; }

.my-boapod-list-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-left: 0.5px !important;
  padding-right: 0.5px !important;
  padding-bottom: 40px; }
  .my-boapod-list-scroll ::-webkit-scrollbar {
    background: transparent; }
  .my-boapod-list-scroll .active {
    box-shadow: #66cc23 0px 0px 0px 2px, #66cc23 0px 4px 6px -1px, #66cc23 0px 0px 0px inset; }

.settings-container ::-webkit-scrollbar {
  width: 10px !important; }

.settings-container ::-webkit-scrollbar-thumb {
  background: #66cc23; }

.settings-container ::-webkit-scrollbar-thumb:hover {
  background: #66cc23; }

.settings-container .ui.tiny.modal {
  margin-top: -300px !important; }

.settings-container .ui.toggle.checkbox input:checked ~ label:before {
  background-color: #66cc23 !important; }

.settings-container .ui.toggle.checkbox label:before {
  background-color: red !important; }

.settings-container .ui.vertical.menu {
  width: 100% !important; }

.settings-container .ui.vertical.menu .item {
  padding: 6px;
  border: 1px solid black !important;
  border-radius: 0px !important; }

.settings-container .ui.vertical.menu .item:hover {
  color: black !important; }

.settings-container .small-checkbox {
  transform: scale(0.6); }

.settings-container .error input {
  border-color: #cc181e !important; }

.settings-container .error {
  border-color: #cc181e !important; }

.settings-container .select-error {
  border-color: #cc181e !important; }

.settings-container div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 1024px) {
  .settings-container .column {
    padding-left: 0px !important; } }

.settings-container .Collapsible {
  background-color: white; }

.settings-container .Collapsible__contentInner {
  padding: 20px 2px 30px 2px;
  border: none;
  border-top: 0; }
  .settings-container .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .settings-container .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.settings-container .Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #66cc23;
  color: white; }
  .settings-container .Collapsible__trigger:after {
    cursor: pointer !important;
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .settings-container .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .settings-container .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.settings-container .CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.settings-container .CustomTriggerCSS--open {
  background-color: darkslateblue; }

.settings-container .Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black; }

.settings-container .customers-container .Collapsible__contentInner {
  margin-top: 10px;
  padding: 0px !important; }

.settings-container .customers-subcontainer .Collapsible__trigger {
  background: #979fa8 !important;
  color: white;
  min-height: 45.24px; }
  .settings-container .customers-subcontainer .Collapsible__trigger.is-closed {
    min-height: 55px !important; }

.settings-container .customer-input > input {
  width: 200px;
  background: transparent !important;
  color: #fff !important;
  padding: 0px;
  border: none;
  border-bottom: 2px solid #000 !important;
  border-radius: 0px; }

.settings-container .contact-input > input {
  width: 200px;
  padding: 0px;
  border: none;
  border-bottom: 2px solid #000;
  border-radius: 0px; }

.ui.mini.modal {
  margin-top: -300px !important;
  margin-left: -180px !important; }

.ui.medium.modal {
  margin-top: -300px !important;
  margin-left: -450px !important; }

.ui.modal > .header {
  padding: 15px !important;
  font-size: 16px !important;
  color: #fff !important;
  background: #66cc23;
  text-align: center; }

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1); }

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent; }

.ui.selection.dropdown .menu > .item {
  font-size: 13px !important; }

.pricing-checkbox input[type="checkbox"] + label::before {
  border-radius: 0px !important;
  border: 3px solid #000;
  color: #000 !important;
  width: 20px;
  height: 20px; }

.pricing-checkbox input[type="checkbox"] + label {
  padding-top: 1.5px; }

.pricing-checkbox input[type="checkbox"]:checked + label::before {
  color: #66cc23 !important;
  background-color: #66cc23;
  border: 3px solid #000;
  width: 20px;
  height: 20px; }

.pricing-checkbox input[type="checkbox"]:checked + label::after {
  background-color: #66cc23;
  border: 3px solid #000;
  color: #66cc23 !important;
  width: 20px;
  height: 20px; }
