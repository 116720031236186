.chat_wrapper .action_tool {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px 0 0; }
  .chat_wrapper .action_tool a {
    font-size: 24px;
    color: #3A3A3A; }

.chat_wrapper .chat_body {
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 92vh;
  min-height: 300px;
  max-height: 720px;
  display: flex; }
  .chat_wrapper .chat_body .chat_left {
    width: 340px; }
    .chat_wrapper .chat_body .chat_left .chat_search {
      position: relative;
      padding: 15px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
      .chat_wrapper .chat_body .chat_left .chat_search .search .input {
        width: 100%; }
        .chat_wrapper .chat_body .chat_left .chat_search .search .input .prompt {
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          padding-right: 50px !important; }
      .chat_wrapper .chat_body .chat_left .chat_search .search.icon {
        font-size: 24px;
        color: #21ba45; }
        .chat_wrapper .chat_body .chat_left .chat_search .search.icon::before {
          content: "\e900";
          font-family: 'boaicon' !important; }
    .chat_wrapper .chat_body .chat_left .userchat-list {
      height: 100%; }
      .chat_wrapper .chat_body .chat_left .userchat-list .userchat-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        padding: 15px; }
        .chat_wrapper .chat_body .chat_left .userchat-list .userchat-btn .button i {
          margin-right: 10px; }
    .chat_wrapper .chat_body .chat_left .contacts-list {
      height: calc(100% - 150px);
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0; }
      .chat_wrapper .chat_body .chat_left .contacts-list .contact {
        position: relative;
        padding: 15px 15px;
        font-size: 0.9em;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        cursor: pointer; }
        .chat_wrapper .chat_body .chat_left .contacts-list .contact.active, .chat_wrapper .chat_body .chat_left .contacts-list .contact:hover, .chat_wrapper .chat_body .chat_left .contacts-list .contact:focus {
          background-color: #F2F9F4; }
        .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap {
          width: 100%;
          margin: 0 auto;
          position: relative;
          display: flex;
          align-items: center; }
          .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .contact_img {
            width: 48px;
            height: 48px;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px;
            background-color: #21ba45;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            padding: 24px; }
            .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .contact_img .contact_img_txt {
              font-size: 18px;
              color: #ffffff; }
              .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .contact_img .contact_img_txt i {
                font-size: 18px;
                color: #fff; }
          .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .meta {
            width: calc(100% - 58px); }
            .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .meta .name {
              display: flex;
              align-items: center;
              justify-content: space-between; }
              .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .meta .name .usertext {
                font-size: 16px;
                font-weight: 800;
                color: #3A3A3A;
                width: calc(100% - 60px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
              .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .meta .name .time {
                font-size: 14px;
                color: #3A3A3A; }
            .chat_wrapper .chat_body .chat_left .contacts-list .contact .wrap .meta .preview {
              margin: 5px 0 0 0;
              padding: 0 0 1px;
              font-weight: 400;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              -moz-transition: 1s all ease;
              -o-transition: 1s all ease;
              -webkit-transition: 1s all ease;
              transition: 1s all ease;
              font-size: 14px;
              color: #747474; }
    .chat_wrapper .chat_body .chat_left .chatleft_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F2F9F4;
      padding: 15px 15px;
      min-height: 74px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
      .chat_wrapper .chat_body .chat_left .chatleft_header h3 {
        font-size: 16px;
        font-weight: 600;
        color: #3A3A3A;
        margin: 0; }
      .chat_wrapper .chat_body .chat_left .chatleft_header i {
        color: #3A3A3A; }
    .chat_wrapper .chat_body .chat_left.addGroup_wrapper {
      position: relative; }
      .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search {
        position: relative;
        padding: 15px 15px 10px 15px; }
        .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .chat_innersearch {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .chat_innersearch .filter_warp {
            position: relative; }
            .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .chat_innersearch .filter_warp span {
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: #21ba45;
              border-radius: 10px;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              -ms-border-radius: 10px;
              -o-border-radius: 10px;
              right: 0;
              top: -4px;
              border: 2px solid #fff; }
          .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .chat_innersearch div.search {
            width: calc(100% - 50px); }
          .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .chat_innersearch .filter_btn {
            padding: 5px; }
            .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .chat_innersearch .filter_btn i {
              font-size: 26px;
              color: #3A3A3A; }
        .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options {
          position: relative;
          padding-top: 15px;
          display: flex;
          flex-wrap: wrap;
          overflow-y: scroll;
          max-height: 100px; }
          .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list {
            display: flex;
            align-items: center;
            margin: 0 0 5px 0; }
            .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item {
              background-color: #EFECEC;
              padding: 5px;
              border-radius: 50px;
              -webkit-border-radius: 50px;
              -moz-border-radius: 50px;
              -ms-border-radius: 50px;
              -o-border-radius: 50px;
              border: 1px solid #DEDEDE;
              display: flex;
              margin-right: 5px; }
              .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_uesr_info {
                display: flex;
                align-items: center;
                justify-content: flex-start; }
                .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_uesr_info .chip_user_img {
                  background-color: #21ba45;
                  width: 24px;
                  height: 24px;
                  border-radius: 50px;
                  -webkit-border-radius: 50px;
                  -moz-border-radius: 50px;
                  -ms-border-radius: 50px;
                  -o-border-radius: 50px;
                  font-size: 10px;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 10px; }
                .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_uesr_info .chip_user_name {
                  font-size: 14px;
                  color: #000;
                  font-weight: 800; }
              .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_action {
                display: flex;
                align-items: center;
                margin-left: 15px; }
                .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_action .close {
                  opacity: 0.8; }
                  .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_action .close i {
                    font-size: 20px;
                    color: #686868; }
                  .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_action .close:hover, .chat_wrapper .chat_body .chat_left.addGroup_wrapper .chat_search .filter_options .list .chips_item .chip_action .close:focus {
                    opacity: 1; }
      .chat_wrapper .chat_body .chat_left.addGroup_wrapper .contacts-list {
        position: relative;
        height: auto;
        min-height: calc(100% - 240px);
        max-height: calc(100% - 240px);
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 74px; }
        .chat_wrapper .chat_body .chat_left.addGroup_wrapper .contacts-list .contact {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .chat_wrapper .chat_body .chat_left.addGroup_wrapper .contacts-list .contact .wrap {
            position: relative;
            width: calc(100% - 40px);
            margin: 0; }
            .chat_wrapper .chat_body .chat_left.addGroup_wrapper .contacts-list .contact .wrap .meta {
              white-space: nowrap;
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              width: 100%; }
              .chat_wrapper .chat_body .chat_left.addGroup_wrapper .contacts-list .contact .wrap .meta .name .usertext {
                width: 100%; }
      .chat_wrapper .chat_body .chat_left.addGroup_wrapper .footer_action {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 99;
        min-height: 74px; }
        .chat_wrapper .chat_body .chat_left.addGroup_wrapper .footer_action .next_btn {
          background-color: #21ba45;
          width: 100%;
          min-height: 74px;
          color: #fff;
          border-radius: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0; }
  .chat_wrapper .chat_body .chat_right {
    width: calc(100% - 340px);
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    height: 100%;
    overflow: hidden;
    position: relative; }
    .chat_wrapper .chat_body .chat_right .contact-profile {
      width: 100%;
      height: 74px;
      line-height: 74px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px; }
      .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-img {
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-img .contact_img_txt {
          font-size: 16px;
          color: #ffffff;
          background-color: #21ba45;
          width: 48px;
          height: 48px;
          border-radius: 50px;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          -ms-border-radius: 50px;
          -o-border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px;
          margin-right: 15px; }
        .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-img .contact-profile-name {
          font-size: 18px;
          font-weight: 800;
          color: #000; }
      .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-action {
        padding: 0 10px 0 0; }
        .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-action a {
          font-size: 24px;
          color: #3A3A3A; }
          .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-action a:hover {
            color: #21ba45; }
        .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-action .dotmenu {
          position: relative;
          padding-left: 18px !important;
          background-color: transparent;
          box-shadow: none; }
          .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-action .dotmenu i.icon {
            background-color: transparent;
            box-shadow: none;
            font-size: 24px; }
          .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-action .dotmenu .menu {
            left: auto;
            right: 0;
            padding: 10px; }
            .chat_wrapper .chat_body .chat_right .contact-profile .contact-profile-action .dotmenu .menu .item {
              position: relative;
              padding: 8px !important;
              border-radius: 5px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
              font-size: .85em; }
    .chat_wrapper .chat_body .chat_right .messages {
      height: auto;
      min-height: calc(100% - 74px);
      max-height: calc(100% - 74px);
      overflow-y: scroll;
      overflow-x: hidden; }
      .chat_wrapper .chat_body .chat_right .messages .messagebox_inner {
        height: auto;
        padding-bottom: 80px; }
      .chat_wrapper .chat_body .chat_right .messages .list .item {
        display: inline-block;
        clear: both;
        margin: 15px 15px 5px 15px;
        width: calc(100% - 25px);
        font-size: 0.9em; }
        .chat_wrapper .chat_body .chat_right .messages .list .item.seprator {
          position: relative;
          text-align: center;
          margin: 15px 15px 5px 15px; }
          .chat_wrapper .chat_body .chat_right .messages .list .item.seprator .seprator_date {
            display: inline-block;
            font-size: 13px;
            color: #3A3A3A;
            display: inline-block;
            font-size: 12px;
            color: #3A3A3A;
            padding: 5px 8px;
            background-color: #F2F9F4;
            position: relative;
            z-index: 99;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px; }
          .chat_wrapper .chat_body .chat_right .messages .list .item.seprator::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            background-color: #F2F9F4;
            height: 1px;
            visibility: visible;
            top: 13px; }
        .chat_wrapper .chat_body .chat_right .messages .list .item .msg_cotainer {
          margin-top: auto;
          margin-bottom: auto;
          padding: 10px 15px;
          position: relative;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px; }
          .chat_wrapper .chat_body .chat_right .messages .list .item .msg_cotainer h4 {
            font-size: 16px;
            font-weight: 800;
            color: #21ba45;
            margin-bottom: 2px; }
          .chat_wrapper .chat_body .chat_right .messages .list .item .msg_cotainer p {
            font-size: 14px;
            color: #3A3A3A;
            margin-bottom: 5px;
            word-break: break-all; }
          .chat_wrapper .chat_body .chat_right .messages .list .item .msg_cotainer .msg_time {
            display: block;
            font-size: 12px;
            color: #3A3A3A;
            text-align: right; }
        .chat_wrapper .chat_body .chat_right .messages .list .item .msg_imagecontainer {
          width: 300px; }
          .chat_wrapper .chat_body .chat_right .messages .list .item .msg_imagecontainer .msg_imagewrapper {
            position: relative;
            background-color: #3A3A3A;
            padding: 2px; }
          .chat_wrapper .chat_body .chat_right .messages .list .item .msg_imagecontainer .msg_cotainer {
            border-top-left-radius: 0;
            border-top-right-radius: 0; }
        .chat_wrapper .chat_body .chat_right .messages .list .item.sent {
          display: flex;
          justify-content: flex-start; }
          .chat_wrapper .chat_body .chat_right .messages .list .item.sent .img_cont_msg {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #21ba45;
            border-radius: 50px; }
            .chat_wrapper .chat_body .chat_right .messages .list .item.sent .img_cont_msg .contact_img_txt {
              font-size: 14px;
              font-weight: 500;
              color: #ffffff; }
            .chat_wrapper .chat_body .chat_right .messages .list .item.sent .img_cont_msg .user_img_msg {
              height: 40px;
              width: 40px;
              border: 1.5px solid #f5f6fa;
              border-radius: 50px;
              -webkit-border-radius: 50px;
              -moz-border-radius: 50px;
              -ms-border-radius: 50px;
              -o-border-radius: 50px; }
          .chat_wrapper .chat_body .chat_right .messages .list .item.sent .msg_cotainer {
            margin-left: 10px;
            background-color: #F2F9F4;
            border-top-left-radius: 0;
            max-width: 400px; }
        .chat_wrapper .chat_body .chat_right .messages .list .item.replies {
          display: flex;
          justify-content: flex-end; }
          .chat_wrapper .chat_body .chat_right .messages .list .item.replies .msg_cotainer {
            background-color: #3A3A3A;
            border-top-right-radius: 0;
            max-width: 400px; }
            .chat_wrapper .chat_body .chat_right .messages .list .item.replies .msg_cotainer p {
              color: #ffffff;
              word-break: break-all; }
            .chat_wrapper .chat_body .chat_right .messages .list .item.replies .msg_cotainer .msg_time {
              color: #ffffff; }
    .chat_wrapper .chat_body .chat_right .message-input {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 99;
      background-color: #F2F9F4;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .chat_wrapper .chat_body .chat_right .message-input .message_footer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        width: calc(100% - 160px); }
        .chat_wrapper .chat_body .chat_right .message-input .message_footer .blank_btn {
          position: absolute;
          font-size: 24px;
          left: 25px;
          top: 15px;
          z-index: 99;
          background-color: transparent;
          padding: 10px; }
        .chat_wrapper .chat_body .chat_right .message-input .message_footer .input {
          width: 100%; }
          .chat_wrapper .chat_body .chat_right .message-input .message_footer .input input {
            padding-left: 50px !important;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            -ms-border-radius: 50px;
            -o-border-radius: 50px; }
      .chat_wrapper .chat_body .chat_right .message-input .input-group-append {
        display: flex; }
        .chat_wrapper .chat_body .chat_right .message-input .input-group-append .button.send_btn {
          position: relative;
          background-color: #21ba45;
          border-radius: 50px;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          -ms-border-radius: 50px;
          -o-border-radius: 50px;
          width: 44px;
          height: 44px; }
          .chat_wrapper .chat_body .chat_right .message-input .input-group-append .button.send_btn i {
            color: #fff; }
        .chat_wrapper .chat_body .chat_right .message-input .input-group-append .button.blank_btn {
          position: relative;
          background-color: transparent;
          width: 44px;
          height: 44px; }
          .chat_wrapper .chat_body .chat_right .message-input .input-group-append .button.blank_btn i {
            font-size: 24px; }

.custom_checkbox {
  position: relative; }
  .custom_checkbox .ui.checkbox {
    width: 24px;
    height: 24px; }
    .custom_checkbox .ui.checkbox input[type=checkbox] {
      position: absolute;
      width: 24px;
      height: 24px; }
    .custom_checkbox .ui.checkbox label:before {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      -webkit-border-radius: 24px;
      -moz-border-radius: 24px;
      -ms-border-radius: 24px;
      -o-border-radius: 24px; }
    .custom_checkbox .ui.checkbox label:after {
      width: 24px;
      height: 24px; }
    .custom_checkbox .ui.checkbox input:checked ~ label:before {
      border: none; }
    .custom_checkbox .ui.checkbox input:focus ~ label:before {
      border-color: 1px solid rgba(34, 36, 38, 0.15); }
    .custom_checkbox .ui.checkbox input:checked ~ label:after {
      background-color: #21ba45;
      border-radius: 24px;
      padding-top: 4px;
      padding-left: 2px;
      color: #fff; }
  .custom_checkbox .ui.checkbox input[type=radio] {
    position: absolute; }

.filter_options {
  position: relative;
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap; }
  .filter_options .list {
    display: flex;
    align-items: center;
    margin: 0 0 5px 0 !important; }
    .filter_options .list .chips_item {
      background-color: #EFECEC;
      padding: 5px !important;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      border: 1px solid #DEDEDE;
      display: flex;
      margin-right: 5px; }
      .filter_options .list .chips_item .chip_uesr_info {
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .filter_options .list .chips_item .chip_uesr_info .chip_user_img {
          background-color: #21ba45;
          width: 24px;
          height: 24px;
          border-radius: 50px;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          -ms-border-radius: 50px;
          -o-border-radius: 50px;
          font-size: 10px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px; }
        .filter_options .list .chips_item .chip_uesr_info .chip_user_name {
          font-size: 14px;
          color: #000;
          font-weight: 800; }
      .filter_options .list .chips_item .chip_action {
        display: flex;
        align-items: center;
        margin-left: 15px; }
        .filter_options .list .chips_item .chip_action .close_btn {
          opacity: 0.8; }
          .filter_options .list .chips_item .chip_action .close_btn i {
            font-size: 20px;
            color: #686868; }
          .filter_options .list .chips_item .chip_action .close_btn:hover, .filter_options .list .chips_item .chip_action .close_btn:focus {
            opacity: 1; }

.modal.custom_modal {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px; }
  .modal.custom_modal .icon:first-child + * {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important; }
  .modal.custom_modal > :first-child:not(.icon) {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important; }
  .modal.custom_modal .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #747474; }
    .modal.custom_modal .close::before {
      content: "\e908";
      font-family: 'boaicon' !important; }
  .modal.custom_modal .header {
    color: #000;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important; }
  .modal.custom_modal .modal_info {
    padding: 40px 0 20px 0; }
    .modal.custom_modal .modal_info h5 {
      font-size: 28px;
      font-weight: bold;
      color: #3A3A3A; }
  .modal.custom_modal .actions {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    background-color: #fff;
    padding: 1.25rem 1.5rem; }
    .modal.custom_modal .actions.modal_footer {
      display: flex;
      align-items: center;
      justify-content: center; }
      .modal.custom_modal .actions.modal_footer .basic:hover, .modal.custom_modal .actions.modal_footer .basic:focus {
        background-color: #000 !important;
        color: #ffffff !important; }

.modal .fitler_list_wrap {
  max-height: 250px;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden; }
  .modal .fitler_list_wrap .item {
    padding: 15px 15px 15px 0;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
    .modal .fitler_list_wrap .item:last-child {
      border-bottom: none; }
    .modal .fitler_list_wrap .item .filter_inner_box {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .modal .fitler_list_wrap .item .filter_inner_box .role_name {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        color: #3A3A3A; }

.fileupload_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .fileupload_wrap .fileupload_input {
    position: relative;
    margin-right: 20px; }
    .fileupload_wrap .fileupload_input .button_uplaod {
      width: 60px;
      height: 60px;
      padding: 15px;
      cursor: pointer;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      background-color: #21ba45; }
      .fileupload_wrap .fileupload_input .button_uplaod i {
        font-size: 24px;
        color: #ffffff; }
    .fileupload_wrap .fileupload_input input[type="file"] {
      width: 50px;
      height: 50px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      cursor: pointer;
      left: 0; }
  .fileupload_wrap .fileupload_label h4 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    margin: 0 0 0 0; }
  .fileupload_wrap .fileupload_label p {
    font-size: 13px;
    font-weight: normal;
    color: #747474;
    margin: 0; }

.text-center {
  text-align: center; }

.mt-1 {
  margin-top: 10px; }

.mt-2 {
  margin-top: 20px; }

.mb-1 {
  margin-bottom: 10px; }

.mb-2 {
  margin-bottom: 20px; }

body ::-webkit-scrollbar {
  width: 5px; }

.groupinfo_wrapper {
  max-width: 340px;
  min-width: 340px;
  position: relative;
  width: 340px;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  overflow: hidden; }
  .groupinfo_wrapper .temp {
    height: 100%;
    overflow: hidden;
    position: relative; }
    .groupinfo_wrapper .temp .groupinfo_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F2F9F4;
      padding: 15px 15px;
      min-height: 74px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
      .groupinfo_wrapper .temp .groupinfo_header h3 {
        font-size: 16px;
        font-weight: 600;
        color: #3A3A3A;
        margin: 0; }
      .groupinfo_wrapper .temp .groupinfo_header a {
        color: #3A3A3A;
        opacity: 0.8; }
        .groupinfo_wrapper .temp .groupinfo_header a:hover {
          opacity: 1; }
    .groupinfo_wrapper .temp .groupinfo_body {
      background-color: #F2F9F4;
      height: calc(100% - 74px);
      overflow-y: scroll;
      overflow-x: hidden; }
      .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock {
        padding: 30px 15px 10px 15px;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        background-color: #ffffff; }
        .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_user {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative; }
          .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_user .groupinfo_user_img {
            width: 144px;
            height: 144px;
            border-radius: 100%;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            -o-border-radius: 100%;
            background-color: #21ba45;
            display: flex;
            align-items: center;
            justify-content: center; }
            .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_user .groupinfo_user_img img {
              object-fit: cover;
              height: 144px;
              width: 100%; }
            .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_user .groupinfo_user_img i {
              font-size: 64px;
              color: #ffffff; }
          .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_user .edit_userinfo {
            position: absolute;
            right: 5px;
            bottom: 0;
            font-size: 18px;
            color: #3A3A3A; }
        .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_edit_list {
          margin: 15px -15px 0 0; }
          .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_edit_list .list .item {
            border-top: 1px solid rgba(34, 36, 38, 0.15);
            padding: 8px 0;
            font-size: 14px;
            font-weight: bold; }
            .groupinfo_wrapper .temp .groupinfo_body .groupinfo_topblock .groupinfo_edit_list .list .item p {
              font-weight: normal;
              color: #747474; }
      .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper {
        position: relative;
        margin: 15px 0;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        border-top: 1px solid rgba(34, 36, 38, 0.15); }
        .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list {
          padding: 15px;
          background-color: #fff; }
          .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item {
            padding: 8px 0;
            border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
            .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .addmember_link {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-start; }
              .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .addmember_link .addmember_icon {
                position: relative;
                width: 44px;
                height: 44px;
                background-color: #3A3A3A;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                margin-right: 15px; }
                .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .addmember_link .addmember_icon i {
                  color: #ffffff; }
              .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .addmember_link .addmember_txt {
                position: relative;
                color: #3A3A3A;
                font-size: 14px;
                font-weight: bold; }
            .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap {
              width: 100%;
              margin: 0 auto;
              position: relative;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center; }
              .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .contact_img {
                width: 48px;
                height: 48px;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                background-color: #21ba45;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-right: 10px;
                padding: 24px; }
                .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .contact_img .contact_img_txt {
                  font-size: 14px;
                  color: #fff; }
                  .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .contact_img .contact_img_txt i {
                    font-size: 18px;
                    color: #fff; }
              .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .meta {
                width: calc(100% - 58px); }
                .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .meta .name {
                  position: relative;
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between; }
                  .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .meta .name .username_info {
                    position: relative;
                    width: calc(100% - 60px); }
                    .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .meta .name .username_info .usertext {
                      font-size: 14px;
                      font-weight: bold;
                      white-space: nowrap;
                      overflow: hidden;
                      -o-text-overflow: ellipsis;
                      text-overflow: ellipsis; }
                    .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .meta .name .username_info .userphone {
                      font-size: 14px;
                      color: #747474; }
                  .groupinfo_wrapper .temp .groupinfo_body .groupmember_wrapper .list .item .wrap .meta .name .userstatus {
                    font-size: 13px; }
      .groupinfo_wrapper .temp .groupinfo_body .groupaction_footer {
        position: relative;
        margin: 15px 0;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        padding: 10px 20px;
        background-color: #ffffff; }
        .groupinfo_wrapper .temp .groupinfo_body .groupaction_footer a {
          font-size: 14px;
          font-weight: bold;
          color: #3A3A3A; }

.chat_search_wrapper .chat_innersearch {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .chat_search_wrapper .chat_innersearch .search {
    width: calc(100% - 50px); }
    .chat_search_wrapper .chat_innersearch .search .input {
      width: 100%; }
      .chat_search_wrapper .chat_innersearch .search .input .prompt {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding-right: 50px !important;
        padding: 10px; }
    .chat_search_wrapper .chat_innersearch .search .icon {
      font-size: 24px;
      color: #21ba45; }
      .chat_search_wrapper .chat_innersearch .search .icon.search::before {
        content: "\E900";
        font-family: 'boaicon' !important; }
  .chat_search_wrapper .chat_innersearch .filter_warp {
    position: relative; }
    .chat_search_wrapper .chat_innersearch .filter_warp .filter_btn {
      padding: 5px; }
      .chat_search_wrapper .chat_innersearch .filter_warp .filter_btn i {
        font-size: 26px;
        color: #3A3A3A; }
    .chat_search_wrapper .chat_innersearch .filter_warp span.filter_on {
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: #21ba45;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      right: 0;
      top: -4px;
      border: 2px solid #fff; }

.userlist_wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }
  .userlist_wrap .contact_img {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    background-color: #21ba45;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 10px;
    padding: 24px; }
    .userlist_wrap .contact_img .contact_img_txt {
      font-size: 18px;
      color: #ffffff; }
  .userlist_wrap .meta {
    width: calc(100% - 58px); }
    .userlist_wrap .meta .name {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .userlist_wrap .meta .name .username_info {
        width: calc(100% - 60px); }
        .userlist_wrap .meta .name .username_info .usertext {
          font-size: 16px;
          font-weight: 800;
          color: #3A3A3A;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          margin-bottom: 3px; }
        .userlist_wrap .meta .name .username_info .usersubtext {
          font-size: 14px;
          font-weight: normal;
          color: #747474;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis; }

.addmembermodal_list {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 15px;
  padding: 5px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden; }
  .addmembermodal_list .item {
    padding: 15px 0;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
    .addmembermodal_list .item:first-child {
      padding: 15px 0 !important; }
    .addmembermodal_list .item:last-child {
      border-bottom: none;
      padding: 15px 0; }

.custom_dropzone {
  position: relative;
  width: 100%;
  height: 100%;
  border: "none"; }
