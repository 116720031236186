.hose-summary .fitter-info .remove-fitter {
  font-weight: bold;
  color: red;
  font-size: 0.8em;
  cursor: pointer; }

.hose-summary .hose-info .clear-hose {
  font-weight: bold;
  color: red;
  font-size: 0.8em;
  cursor: pointer; }
