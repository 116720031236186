.hose-search .results {
  width: 100% !important; }

.hose-search .search-box .input {
  width: 100%; }

.hose-search .hose-categories-box .filter-content {
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 0 0 20px #eee;
  margin: 5px;
  border-bottom: 2px solid #00001e;
  color: #111;
  word-wrap: break-word; }
  .hose-search .hose-categories-box .filter-content:hover {
    background: #eee;
    border-bottom: 2px solid #66cc23; }
  .hose-search .hose-categories-box .filter-content .filter-content-image {
    padding: 5px; }
    .hose-search .hose-categories-box .filter-content .filter-content-image img {
      height: 80px; }

.hose-search .product-card .product-header {
  max-width: calc(100% - 100px);
  word-wrap: break-word;
  margin-top: -32px !important;
  font-size: 0.8em; }
  .hose-search .product-card .product-header .product-header-stock {
    font-size: 1.2em;
    padding-right: 5px; }
  .hose-search .product-card .product-header .card-meta {
    font-weight: normal;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.4); }

@media screen and (max-width: 480px) {
  .hose-search .product-card .card-description .product-info-grid {
    padding-right: 0px; } }

.hose-search .product-card .card-description .product-info-grid .add-card-column {
  margin-top: 10px;
  border-left: 1px solid #eee; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .hose-search .product-card .card-description .product-info-grid .add-card-column {
      margin: 14px auto; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .hose-search .product-card .card-description .product-info-grid .add-card-column {
      margin: 14px auto; } }
  @media screen and (max-width: 480px) {
    .hose-search .product-card .card-description .product-info-grid .add-card-column {
      margin: 14px auto; } }
  .hose-search .product-card .card-description .product-info-grid .add-card-column .cart-button {
    margin-top: 44px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .hose-search .product-card .card-description .product-info-grid .add-card-column .cart-button {
        margin-top: 27px; } }
  .hose-search .product-card .card-description .product-info-grid .add-card-column .quantity-form {
    margin-top: 5px; }
    .hose-search .product-card .card-description .product-info-grid .add-card-column .quantity-form .quantity-label {
      margin-top: 6px;
      text-align: center;
      font-weight: bold; }
    .hose-search .product-card .card-description .product-info-grid .add-card-column .quantity-form .quantity-width {
      margin-top: 5px;
      width: 100%; }
