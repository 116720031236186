.member-form .header {
  padding-bottom: 6px; }

.member-form .form-panel {
  border-top: 1px solid lightgray; }
  .member-form .form-panel .add-member {
    display: flex; }
    .member-form .form-panel .add-member .button {
      margin: auto;
      margin-top: 26px; }

.member-form .member-list {
  border-top: 1px solid lightgray; }
