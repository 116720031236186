@font-face {
  font-family: "calibre-regular";
  src: local("calibre-regular"), url(../../../assets/fonts/CalibreRegular.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */ }

@font-face {
  font-family: "calibre-semibold";
  src: local("calibre-semibold"), url(../../../assets/fonts/CalibreSemibold.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */ }

@font-face {
  font-family: "calibre-bold";
  src: local("calibre-bold"), url(../../../assets/fonts/CalibreBold.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */ }

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1); }

.ui.dimmer .ui.workaround.loader #text {
  border-color: rgba(0, 0, 0, 0.1); }

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent; }

.main-menu-public {
  position: fixed;
  width: 100%;
  min-height: 60px !important;
  height: 60px;
  margin-top: -130px;
  z-index: 99;
  background: #0c0d0e;
  min-width: 320px; }

.main-menu-public-mobile {
  position: fixed;
  width: 100%;
  min-height: 80px !important;
  height: 80px;
  margin-top: -130px;
  z-index: 99;
  background: #0c0d0e;
  min-width: 320px; }

.login-title {
  margin: 10px auto;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
  font-family: Lato;
  font-size: 1.125em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000; }

.feature-heading {
  width: 100%;
  height: 660px;
  position: relative; }
  .feature-heading .feature-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .feature-heading .feature-content .content-box {
      position: relative;
      padding: 50px;
      height: 100%;
      width: 100%;
      min-width: 320px;
      margin: 0 auto;
      max-width: 1338px; }
      @media (min-width: 600px) and (max-width: 1999px) {
        .feature-heading .feature-content .content-box {
          max-width: 1338px; } }
      @media (min-width: 3000px) and (max-width: 6999px) {
        .feature-heading .feature-content .content-box {
          padding-left: 500px !important;
          max-width: 1905px; } }
    .feature-heading .feature-content .content-box-mobile {
      position: relative;
      padding: 50px 20px;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      max-width: 700px;
      text-align: center; }
    .feature-heading .feature-content .content-box2 {
      position: relative;
      padding: 0px;
      padding-top: 80px;
      padding-right: 20px;
      height: 100%;
      width: 100%;
      min-width: 320px;
      margin: 0 auto;
      max-width: 1338px; }
    .feature-heading .feature-content .content-box3 {
      position: relative;
      padding: 0px;
      padding-top: 80px;
      padding-right: 20px;
      height: 100%;
      width: 100%;
      min-width: 320px;
      margin: 0 auto;
      max-width: 1338px; }
      @media (min-width: 600px) and (max-width: 1600px) {
        .feature-heading .feature-content .content-box3 {
          padding-left: 900px; } }
      @media (min-width: 1601px) and (max-width: 2600px) {
        .feature-heading .feature-content .content-box3 {
          padding-left: 1050px !important;
          max-width: 1500px !important; } }
      @media (min-width: 2601px) and (max-width: 6600px) {
        .feature-heading .feature-content .content-box3 {
          padding-left: 1350px !important;
          max-width: 1900px !important; } }
  .feature-heading .feature-left {
    display: inline-block;
    width: 60%;
    height: 660px;
    z-index: 5;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%); }
    @media (min-width: 600px) and (max-width: 1999px) {
      .feature-heading .feature-left {
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important; } }
    @media (min-width: 3000px) and (max-width: 6999px) {
      .feature-heading .feature-left {
        clip-path: polygon(0 0, 90% 0, 80% 100%, 0 100%) !important; } }
    .feature-heading .feature-left .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .feature-heading .feature-left .overlay img {
        object-fit: cover;
        width: 75vw;
        height: 660px; }
    .feature-heading .feature-left .overlay::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.82); }
  .feature-heading .feature-left-mobile {
    display: inline-block;
    width: 100%;
    height: 660px;
    z-index: 5;
    position: relative;
    clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%); }
    .feature-heading .feature-left-mobile .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .feature-heading .feature-left-mobile .overlay img {
        object-fit: cover;
        width: 100vw;
        height: 660px; }
    .feature-heading .feature-left-mobile .overlay::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.82); }
  .feature-heading .feature-left2 {
    display: inline-block;
    width: 50%;
    height: 660px;
    clip-path: polygon(0 0, 100% 0%, 70% 100%, 0% 100%);
    z-index: 6;
    position: relative; }
    @media (min-width: 600px) and (max-width: 1999px) {
      .feature-heading .feature-left2 {
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important; } }
    @media (min-width: 3000px) and (max-width: 6999px) {
      .feature-heading .feature-left2 {
        clip-path: polygon(0 0, 90% 0, 80% 100%, 0 100%) !important;
        width: 55%; } }
  .feature-heading .feature-left3 {
    display: inline-block;
    width: 55%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 60% 100%, 0 100%);
    z-index: 5;
    background: url("../../../assets/images/login/feature-left-3.png");
    position: relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important; }
    .feature-heading .feature-left3 .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      /* Adjust the opacity as needed */ }
  .feature-heading .feature-center {
    display: inline-block;
    position: relative;
    background-color: #66cc23;
    mix-blend-mode: multiply;
    height: 660px;
    z-index: 4;
    width: 60%;
    margin-left: -53%;
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%); }
    @media (min-width: 600px) and (max-width: 1999px) {
      .feature-heading .feature-center {
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important; } }
    @media (min-width: 3000px) and (max-width: 6999px) {
      .feature-heading .feature-center {
        clip-path: polygon(0 0, 90% 0, 80% 100%, 0 100%) !important;
        margin-left: -58% !important; } }
  .feature-heading .feature-center-mobile {
    display: inline-block;
    position: relative;
    background-color: #66cc23;
    mix-blend-mode: multiply;
    height: 660px;
    z-index: 4;
    width: 100%;
    margin-top: -600px;
    clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%); }
  .feature-heading .feature-center-mobile2 {
    display: inline-block;
    position: relative;
    background-color: #66cc23;
    height: 660px;
    z-index: 4;
    width: 100%;
    margin-top: -600px;
    clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%); }
  .feature-heading .feature-center2 {
    display: inline-block;
    background-color: #66cc23;
    width: 50%;
    height: 660px;
    margin-left: -43%;
    clip-path: polygon(0 0, 100% 0%, 70% 100%, 0% 100%);
    z-index: 4;
    position: relative; }
    @media (min-width: 600px) and (max-width: 1999px) {
      .feature-heading .feature-center2 {
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important; } }
    @media (min-width: 3000px) and (max-width: 6999px) {
      .feature-heading .feature-center2 {
        clip-path: polygon(0 0, 89% 0, 78% 100%, 0 100%) !important;
        margin-left: -48% !important; } }
  .feature-heading .feature-center3 {
    display: inline-block;
    position: relative;
    background-color: #66cc23;
    height: 660px;
    z-index: 4;
    width: 60%;
    margin-left: -53%;
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%); }
    @media (min-width: 600px) and (max-width: 1999px) {
      .feature-heading .feature-center3 {
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%) !important; } }
    @media (min-width: 3000px) and (max-width: 6999px) {
      .feature-heading .feature-center3 {
        clip-path: polygon(0 0, 90% 0, 80% 100%, 0 100%) !important;
        margin-left: -58% !important; } }
  .feature-heading .feature-right {
    display: inline-block;
    width: 40%;
    margin-left: -37%;
    height: 660px;
    z-index: 3;
    position: relative; }
    .feature-heading .feature-right img {
      position: absolute;
      top: 0px;
      object-fit: fill;
      width: 60vw;
      height: 660px;
      margin-left: 10vw;
      z-index: 2; }
      @media (min-width: 2600px) and (max-width: 8000px) {
        .feature-heading .feature-right img {
          width: 70vw !important;
          object-fit: cover !important; } }
  .feature-heading .feature-right-mobile {
    display: inline-block;
    width: 100%;
    margin-top: -300px;
    z-index: 3;
    position: relative; }
    .feature-heading .feature-right-mobile img {
      position: absolute;
      top: 0px;
      object-fit: cover;
      width: 100vw;
      z-index: 2;
      margin-top: 30px; }
  .feature-heading .feature-right-mobile2 {
    display: inline-block;
    width: 100%;
    height: 960px;
    margin-top: -300px;
    z-index: 3;
    position: relative; }
    .feature-heading .feature-right-mobile2 .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .feature-heading .feature-right-mobile2 .overlay img {
        object-fit: cover;
        width: 100vw;
        height: 960px; }
    .feature-heading .feature-right-mobile2 .overlay::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.82); }
  .feature-heading .feature-right2 {
    display: inline-block;
    width: 58%;
    height: 660px;
    margin-left: -15%;
    z-index: 3;
    position: relative; }
    .feature-heading .feature-right2 .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .feature-heading .feature-right2 .overlay img {
        object-fit: cover;
        width: 85.2vw;
        height: 660px;
        z-index: 2; }
    .feature-heading .feature-right2 .overlay::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.82); }
  .feature-heading .feature-right3 {
    display: inline-block;
    width: 40%;
    margin-left: -37%;
    height: 660px;
    z-index: 3;
    position: relative; }
    .feature-heading .feature-right3 .form-background {
      position: absolute;
      top: 0px;
      /* Specify the top coordinate */
      object-fit: cover;
      width: 85.2vw;
      height: 660px;
      z-index: 2;
      background-color: #fff; }
  .feature-heading .text-bg {
    height: 120px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #000;
    opacity: 0.8;
    min-height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .feature-heading .text {
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 52px;
    position: relative;
    bottom: -10px;
    padding: 0;
    text-align: center;
    color: #fff;
    font-family: "calibre-semibold", sans-serif;
    margin: 0; }
  .feature-heading .content-max-width {
    padding: 0;
    max-width: 100%;
    position: relative;
    height: 660px;
    width: 100%;
    min-width: 320px;
    margin: 0 auto; }
  .feature-heading .content-max-width-mobile {
    padding: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    margin: 0 auto; }
  .feature-heading .content-max-width-mobile2 {
    padding: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: -7px; }

.body-container {
  min-height: 400px; }
  .body-container .heading-content {
    padding: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 90px;
    margin-bottom: 87px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto; }
    @media (min-width: 1280px) {
      .body-container .heading-content {
        padding: 0; } }
    .body-container .heading-content .heading {
      width: 600px;
      margin-bottom: 0;
      text-align: center;
      padding-top: 30px; }
      @media (min-width: 1025px) {
        .body-container .heading-content .heading {
          text-align: left; } }
      @media (max-width: 1110px) {
        .body-container .heading-content .heading {
          margin: auto; } }
      .body-container .heading-content .heading * {
        color: #151819;
        font-family: "calibre-bold", sans-serif;
        font-size: 42px;
        font-weight: 2000;
        letter-spacing: 0;
        line-height: 48px; }
        .body-container .heading-content .heading * strong {
          color: #66cc23; }
        @media (max-width: 1025px) {
          .body-container .heading-content .heading * {
            font-size: 24px !important;
            line-height: 32px !important; } }
      .body-container .heading-content .heading img.chevron {
        width: 78px;
        margin: 12px 0 0; }
        @media (max-width: 1025px) {
          .body-container .heading-content .heading img.chevron {
            margin: 0; } }
    .body-container .heading-content .heading-public {
      width: 500px;
      margin: auto;
      text-align: left; }
      @media (min-width: 1025px) {
        .body-container .heading-content .heading-public {
          text-align: left; } }
      @media (max-width: 1110px) {
        .body-container .heading-content .heading-public {
          margin: auto; } }
      .body-container .heading-content .heading-public * {
        color: #151819;
        font-family: "calibre-bold", sans-serif;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 25px;
        font-weight: lighter; }
        .body-container .heading-content .heading-public * strong {
          color: #66cc23;
          font-size: 42px; }
        @media (max-width: 1025px) {
          .body-container .heading-content .heading-public * {
            font-size: 24px !important;
            line-height: 25px !important; } }
    .body-container .heading-content .box-content {
      padding-top: 9px;
      width: 540px;
      padding-top: 9px; }
      @media (max-width: 1200px) {
        .body-container .heading-content .box-content {
          width: 450px; } }
      @media (max-width: 1110px) {
        .body-container .heading-content .box-content {
          width: 100%; } }

.feature-box {
  margin-top: 30px;
  margin-bottom: -30px;
  max-width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }
  @media (min-width: 1025px) {
    .feature-box {
      flex-wrap: nowrap; } }
  .feature-box .column-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 470px;
    background-color: #151819;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important; }
    @media (min-width: 1025px) {
      .feature-box .column-text {
        width: 62%;
        padding: 0; } }
    @media (min-width: 1280px) {
      .feature-box .column-text {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end; } }
    @media (max-width: 1024px) {
      .feature-box .column-text {
        height: 100%; } }
    .feature-box .column-text .text-container {
      width: 90%;
      margin-bottom: 0;
      margin-right: 0;
      padding: 50px 0; }
      @media (min-width: 1450px) {
        .feature-box .column-text .text-container {
          width: 810px;
          margin-top: 50px;
          margin-bottom: 50px;
          padding: 0;
          margin-right: 71px; } }
      .feature-box .column-text .text-container * {
        text-align: center; }
        @media (min-width: 1025px) {
          .feature-box .column-text .text-container * {
            text-align: left; } }
      .feature-box .column-text .text-container .wysiwyg {
        margin-bottom: 41px; }
      .feature-box .column-text .text-container .app-stores a {
        display: inline-block;
        margin-right: 0; }
        @media (min-width: 450px) {
          .feature-box .column-text .text-container .app-stores a {
            margin-right: 15px; } }
      .feature-box .column-text .text-container .app-stores .icon {
        width: 196px;
        margin-bottom: 20px; }
        @media (min-width: 1025px) {
          .feature-box .column-text .text-container .app-stores .icon {
            margin-bottom: 0; } }
      .feature-box .column-text .text-container h2 {
        color: #fff;
        font-family: "calibre-semibold", sans-serif;
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 32px;
        text-transform: uppercase;
        padding: 0 0 20px;
        margin: 0 0 40px;
        border-bottom: 3px solid #66cc23; }
      .feature-box .column-text .text-container .wysiwyg p {
        color: #fff;
        font-family: "calibre-regular", sans-serif;
        font-size: 20px;
        font-weight: 100;
        line-height: 30px; }
  .feature-box .column-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 470px;
    background-color: #151819;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
    width: 100%;
    height: 470px;
    background: url("../../../assets/images/boahub.png");
    background-size: 100% 100%; }
    @media (min-width: 1025px) {
      .feature-box .column-image {
        width: 38%;
        padding: 0; } }
    @media (min-width: 1280px) {
      .feature-box .column-image {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end; } }
    @media (max-width: 1024px) {
      .feature-box .column-image {
        height: 470px; } }
  .feature-box .column-empty {
    width: 100%;
    background-color: #151819;
    min-height: 0px; }
    @media (min-width: 600px) {
      .feature-box .column-empty {
        min-height: 470px; } }
    @media (min-width: 1025px) {
      .feature-box .column-empty {
        width: 12%;
        padding: 0; } }

.login-container {
  max-width: 650px;
  margin: 10px 0px 10px auto !important; }
  @media (max-width: 1110px) {
    .login-container {
      margin: 10px auto !important; } }
  .login-container .create-account-box {
    padding: 10px 40px 0px 10px;
    margin-top: -40px; }
    .login-container .create-account-box .create-account {
      cursor: pointer;
      margin-left: 0px; }
      .login-container .create-account-box .create-account:hover {
        text-decoration: underline;
        color: green; }
  .login-container .login {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: auto;
    min-height: calc(100vh - 600px); }
    .login-container .login .login-box {
      background: transparent;
      padding: 10px;
      width: 100%;
      margin: 10px auto;
      margin-bottom: 23px; }
      .login-container .login .login-box .input-box {
        padding: 0.678571em !important;
        border-radius: 10px !important;
        border: 0px 0px 1px 0px solid #333 !important; }
        .login-container .login .login-box .input-box input[type="email"] {
          border: 1px solid red !important; }
        .login-container .login .login-box .input-box .show-password {
          margin-left: -18px !important;
          margin-top: 9px !important; }
      .login-container .login .login-box .error-message {
        word-wrap: break-word; }
        .login-container .login .login-box .error-message .resend-email-link {
          cursor: pointer;
          color: red; }
          .login-container .login .login-box .error-message .resend-email-link:hover {
            color: green; }
      .login-container .login .login-box .userIcon {
        width: 17px;
        position: absolute;
        margin-top: 9px;
        margin-left: 9px; }
      .login-container .login .login-box .log-in-button {
        width: 100px !important;
        height: 42px;
        mix-blend-mode: undefined;
        border-radius: 10px; }
      .login-container .login .login-box .forgot-password {
        font-size: 0.875em;
        float: right;
        cursor: pointer; }
        .login-container .login .login-box .forgot-password:hover {
          text-decoration: underline;
          color: green; }
      .login-container .login .login-box .forgot-password-grid-margin {
        margin-top: 20px;
        margin-bottom: 43px; }
      @media screen and (max-width: 1024px) {
        .login-container .login .login-box {
          width: 400px !important; }
          .login-container .login .login-box .create-account {
            margin-left: 40px; } }
      @media screen and (max-width: 768px) {
        .login-container .login .login-box .create-account .create-account-box {
          padding: 10px 10px 0px 40px;
          margin-top: -27px;
          margin-bottom: 0px; } }
      @media screen and (max-width: 414px) {
        .login-container .login .login-box {
          margin-left: -15px;
          padding-bottom: 40px;
          width: 360px !important; }
          .login-container .login .login-box .create-account {
            margin-left: 40px; } }
      @media screen and (max-width: 375px) {
        .login-container .login .login-box {
          padding: 10px 10px 50px 5%;
          width: 300px !important; }
          .login-container .login .login-box .login-title {
            font-family: Lato;
            font-size: 0.96em; }
          .login-container .login .login-box .login-box {
            margin-top: -30px; }
          .login-container .login .login-box .input-box {
            border-radius: 10px !important; } }
      @media screen and (max-width: 360px) {
        .login-container .login .login-box {
          padding: 10px 10px 56px 5%;
          margin-left: -15px;
          width: 300px !important; }
          .login-container .login .login-box .login-title {
            font-family: Lato;
            font-size: 0.78em; }
          .login-container .login .login-box .input-box {
            border-radius: 10px !important; }
          .login-container .login .login-box .create-account {
            margin-left: -10px; } }
      @media screen and (max-width: 320px) {
        .login-container .login .login-box {
          padding: 10px 10px 10px 2%;
          margin-top: -30px;
          width: 300px !important; }
          .login-container .login .login-box .login-title {
            font-family: Lato;
            font-size: 0.62em; }
          .login-container .login .login-box .input-box {
            border-radius: 10px !important; }
          .login-container .login .login-box .create-account {
            margin-left: 20px; } }
    .login-container .login .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      float: left; }
      .login-container .login .button-container .signup {
        font-weight: bold; }
        .login-container .login .button-container .signup :hover {
          cursor: pointer; }
  @media screen and (max-width: 414px) {
    .login-container {
      width: 100%; }
      .login-container .create-account-box {
        padding: 0px 10px 0px 40px;
        margin-top: -40px;
        margin-bottom: 30px; } }
  @media screen and (max-width: 375px) {
    .login-container {
      width: 100%; }
      .login-container .create-account-box {
        padding: 0px 10px 0px 20px;
        margin-top: -78px;
        margin-bottom: 0px; } }
  @media screen and (max-width: 360px) {
    .login-container {
      width: 100%; } }
  @media screen and (max-width: 320px) {
    .login-container {
      width: 100%; }
      .login-container .create-account-box {
        padding: 0px 10px 0px 0px;
        margin-top: 0px !important;
        font-size: 0.87em;
        margin-bottom: 10px; } }

.app-message-box {
  background: #fff;
  padding: 20px;
  margin-top: 65px;
  text-align: center; }

.mobile-app-link {
  padding: 10px;
  background: #eee;
  cursor: pointer;
  border: 1px solid #aaa;
  text-decoration: none;
  color: #333;
  border-radius: 5px; }

@media screen and (max-width: 480px) {
  .login-Button {
    width: 100%;
    text-align: center; } }
