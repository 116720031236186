.hose-confirm-modal .fitter-image-right {
  z-index: 10;
  float: left;
  width: 180px !important;
  -webkit-transform: rotateX(180deg) scaleX(-1) !important;
  transform: rotateX(180deg) scaleX(-1) !important;
  margin-left: -40px;
  margin-top: -33px; }

.hose-confirm-modal .fitter-image-left {
  z-index: 10;
  width: 180px;
  float: right;
  margin-right: -40px;
  margin-top: -23px; }

.hose-confirm-modal .hose-bore {
  padding: 0px !important;
  text-align: center;
  margin-top: 40px;
  margin: 10px auto;
  margin-left: 0px !important;
  margin-right: 0px !important; }

.hose-confirm-modal .bore-image {
  height: 80px !important;
  z-index: 10000;
  margin-top: 30px;
  width: 100%; }

.hose-confirm-modal .hose-price-info {
  padding: 10px;
  background: #f8f8f8;
  float: right;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px; }
  .hose-confirm-modal .hose-price-info div i {
    float: right; }
  .hose-confirm-modal .hose-price-info i {
    float: right; }

.hose-confirm-modal .hose-length {
  width: 400px;
  margin: auto; }

.hose-confirm-modal .overall-length {
  width: 629px;
  margin: auto; }
