.filter-box {
  cursor: pointer;
  font-size: 0.75em;
  padding: 10px; }
  .filter-box .selected {
    border-bottom: 2px solid #66cc23 !important; }
  .filter-box .filter-content {
    border: 1px solid #eee;
    border-radius: 3px;
    box-shadow: 0 0 20px #eee;
    margin: 5px;
    border-bottom: 2px solid #00001e;
    color: #111;
    word-wrap: break-word; }
    .filter-box .filter-content:hover {
      background: #eee;
      border-bottom: 2px solid #66cc23; }
    .filter-box .filter-content .filter-content-image {
      padding: 5px; }
      .filter-box .filter-content .filter-content-image img {
        height: 80px; }
