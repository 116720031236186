.search_button {
  margin: 26px 0px 0px 0px !important; }
  @media (min-width: 481px) and (max-width: 748px) {
    .search_button {
      margin: 0px 0px 0px 0px !important; } }
  @media screen and (max-width: 480px) {
    .search_button {
      margin: 0px 0px 0px 0px !important; } }

.clear-button {
  margin-left: 5px !important; }
