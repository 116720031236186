.verify-email-box {
  min-height: calc(100vh - 265px); }
  .verify-email-box .message {
    text-align: center;
    margin: 0px auto;
    font-size: 1.125em;
    height: 221px;
    width: 450px; }
  .verify-email-box .success {
    color: green; }
