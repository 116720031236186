.feedback-button {
    position: fixed;
    right: 0px;
    bottom: 100px;
    background-color: #66cc23;
    color: white;
    border: none;
    border-radius: 100px 0 0 100px;
    cursor: pointer;
    z-index: 1000;
    width: 100px;
    padding-right: 10px;
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: center;
    transition: width 0.3s ease;
}

.feedback-button:hover {
    width: 120px;
}


.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
    /* Transition for moving the content */
}

.feedback-button:hover .content-container {
    transform: translateX(-15%);
    /* Move the content to the left */
}

.feedback-icon {
    height: 40px;
    transition: transform 0.3s ease;
    /* Transition for the icon movement */
}

.feedback-button:hover .feedback-icon {
    transform: rotateY(180deg);
    /* Rotate the icon */
}