.change-password-box {
  min-height: calc(100vh - 184px); }
  .change-password-box .change-password {
    width: 100% !important; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .change-password-box .change-password {
        display: flex; } }
    @media screen and (max-width: 480px) {
      .change-password-box .change-password {
        display: flex; } }
  .change-password-box .change-password-container {
    margin: 60px auto;
    border: 1px solid #eee;
    box-shadow: 0 0 30px #eee;
    border-radius: 10px;
    width: 400px;
    padding: 20px;
    padding-bottom: 30px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .change-password-box .change-password-container {
        width: 75%;
        margin: auto; } }
    @media (min-width: 481px) and (max-width: 748px) {
      .change-password-box .change-password-container {
        width: 80%; } }
    @media screen and (max-width: 480px) {
      .change-password-box .change-password-container {
        width: 100%;
        margin: auto; } }
    .change-password-box .change-password-container .home-page-link {
      display: flow-root;
      justify-content: center; }
    .change-password-box .change-password-container .button-float {
      float: right;
      margin-right: 0px !important;
      margin-left: 10px !important; }
    .change-password-box .change-password-container .password-criteria-message {
      font-size: 0.75em;
      color: #999;
      word-wrap: none; }
  .change-password-box .reset-password-note {
    margin-left: 79px !important;
    margin-top: -22px !important; }
