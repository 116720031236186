/* Position and sizing of burger button */
.bm-burger-button {
  z-index: 1000;
  position: fixed;
  width: 21px;
  height: 17px;
  right: 20px;
  top: 45px; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #00001e; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px; }

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7; }

/* General sidebar styles */
.bm-menu {
  background: #00001e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em; }
  @media (min-width: 481px) and (max-width: 748px) {
    .bm-menu {
      padding: 2em 1em 0; } }
  @media screen and (max-width: 1000px) {
    .bm-menu {
      padding: 2em 1em 0; } }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47; }

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em; }

/* Individual item */
.bm-item {
  display: inline-block; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0; }

.bm-menu-wrap {
  top: 110px; }

.bm-burger-bars {
  background: white !important; }

.bm-burger-bars {
  position: absolute;
  height: 20%;
  left: 0px;
  right: 0px;
  top: 0%;
  opacity: 1;
  background: white; }

.burger-menu {
  display: none; }
  @media (min-width: 481px) and (max-width: 748px) {
    .burger-menu {
      display: block; } }
  @media screen and (max-width: 1000px) {
    .burger-menu {
      display: block; } }
