.info_display {
    display: flex;
    width: 100%;
}

.section_display {
    display: flex;
    width: 250px;
    flex-direction: column;
    margin-right: 50px;
    margin-top: 20px;
    padding: 20px;
    border: solid 2px #66cb23;
    border-radius: 10px;

}

.section_title {
    text-transform: capitalize;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding-top: 5px;
    background-color: rgb(180, 180, 180);
    color: white;
    margin-bottom: 25px;
}

.item_title {
    font-size: 16px;
    font-weight: bold;
    /* margin-bottom: 5px; */
    color: rgb(94, 94, 94);
    text-transform: capitalize;
}

.item_value {
    font-size: 32px;
    margin-bottom: 10px;
}

.item_value_clickable {
    position: relative;
    padding: 0;
    font-size: 32px;
    border: none;
    margin-bottom: 10px;
    color: rgb(7, 176, 255);
    font-weight: 500;
    cursor: pointer;
    overflow: hidden;
}

.item_value_clickable:hover {
    text-decoration: underline 2px dotted;
    color: rgb(0, 123, 180);
}

.assembly_search_button {
    margin: 0;
    width: 100px;
    height: 40px;
    background-color: #66cb23;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}