.product_detail .title {
  color: black !important;
  background: #f5f4f4;
  text-align: center; }
  .product_detail .title:hover {
    background: #f5f4f4 !important; }

@media (min-width: 481px) and (max-width: 748px) {
  .product_detail .details_header {
    display: none !important; } }

@media screen and (max-width: 480px) {
  .product_detail .details_header {
    display: none !important; } }

.product_detail .details_header_mobile {
  display: none;
  font-weight: bold; }
  @media (min-width: 481px) and (max-width: 748px) {
    .product_detail .details_header_mobile {
      display: block !important; } }
  @media screen and (max-width: 480px) {
    .product_detail .details_header_mobile {
      display: block !important; } }

.product_detail .add-to-cart .content {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.product_detail .order-history-stock-code {
  word-wrap: break-word; }

.referenceText {
  word-break: break-word; }

.no-order-section {
  display: flex; }
  .no-order-section .no-order-text {
    margin: auto;
    height: 10vh;
    padding-top: 20px; }

@media (min-width: 480px) and (max-width: 1024px) {
  .cart-product-image {
    margin-top: 28px;
    height: 37px;
    width: 43px; } }

@media screen and (max-width: 480px) {
  .cart-product-image {
    height: 25px;
    width: 25px; } }
