@media screen and (max-width: 480px) {
  .my-asset .asset-statistics .statistic {
    padding: 10px 0px; } }

.my-asset .asset-statistics .statistic-value {
  color: green; }
  @media screen and (max-width: 480px) {
    .my-asset .asset-statistics .statistic-value {
      font-size: 2.4rem !important; } }

@media screen and (max-width: 480px) {
  .my-asset .asset-Button {
    width: 140%;
    text-align: center;
    justify-content: center; } }
