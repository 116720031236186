.stock-review-list {
  padding: 10px 6px !important;
  border-top: none !important;
  border-bottom: 1px solid lightgray; }
  .stock-review-list .link-button {
    cursor: pointer;
    color: blue; }
    .stock-review-list .link-button:hover {
      text-decoration: underline; }

.linkToPage {
  font-size: 0.8em;
  padding-left: 86px;
  cursor: pointer;
  margin-top: 1% !important; }
  .linkToPage:hover {
    color: blue; }

.link_To_Page {
  font-size: 0.8em;
  padding-left: 86px;
  cursor: pointer;
  margin-top: -5% !important; }
  .link_To_Page:hover {
    color: blue; }
