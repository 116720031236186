.caf-container {
  min-height: calc(100vh - 330px); }
  .caf-container .cafForm-box {
    width: 60%;
    margin: 30px auto;
    border: 1px solid #eee;
    box-shadow: 0 0 30px #eee;
    padding: 20px;
    border-radius: 10px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      .caf-container .cafForm-box {
        width: 75%; } }
    @media (min-width: 481px) and (max-width: 748px) {
      .caf-container .cafForm-box {
        width: 80%; } }
    @media screen and (max-width: 480px) {
      .caf-container .cafForm-box {
        width: 100%; } }
    .caf-container .cafForm-box .button-float {
      float: right;
      margin-top: 20px;
      margin-right: 0px !important;
      margin-left: 10px;
      margin-bottom: 20px; }
    .caf-container .cafForm-box .error-message {
      color: red; }
  .caf-container .success-message {
    color: green;
    margin-top: 100px;
    text-align: center;
    padding: 10px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px #eee; }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100% !important; }
