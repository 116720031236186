.verify-email-box {
  min-height: calc(100vh - 365px); }
  .verify-email-box .message {
    text-align: center;
    margin: 0px auto;
    font-size: 1.125em;
    height: 300px; }
  .verify-email-box .success {
    color: green; }

.verify-ebox .msg {
  text-align: center;
  margin: 0px auto;
  font-size: 1.125em;
  height: auto; }

.verify-ebox .success {
  color: green; }
