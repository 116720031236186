.vertical-buttons {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  /* Center buttons horizontally */ }

.vertical-buttons .ui.button {
  margin-bottom: 10px;
  /* Space between buttons */ }

hr {
  height: 1px !important;
  background-color: #ccc;
  border: none;
  margin-left: 0px;
  margin-right: 1rem; }

.stock-adjustment ::-webkit-scrollbar {
  width: 10px !important; }

.stock-adjustment ::-webkit-scrollbar-thumb {
  background: #66cc23; }

.stock-adjustment ::-webkit-scrollbar-thumb:hover {
  background: #66cc23; }

.stock-adjustment div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 1024px) {
  .stock-adjustment .column {
    padding-left: 0px !important; } }

.stock-adjustment .adjustment-list-section {
  height: 730px; }
  .stock-adjustment .adjustment-list-section hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-left: 0px;
    margin-right: 1rem; }
    @media (min-width: 1025px) {
      .stock-adjustment .adjustment-list-section hr {
        margin-left: 20px; } }
  .stock-adjustment .adjustment-list-section .adjustment-status {
    padding-bottom: 10px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 1rem; }
    .stock-adjustment .adjustment-list-section .adjustment-status button {
      padding: 8px;
      margin-top: 5px; }
    .stock-adjustment .adjustment-list-section .adjustment-status .active-status {
      background-color: #66cc23;
      color: #fff;
      max-width: 292px; }
    @media (min-width: 1025px) {
      .stock-adjustment .adjustment-list-section .adjustment-status {
        padding-left: 20px; } }
  .stock-adjustment .adjustment-list-section .adjustment-list {
    height: 600px; }
    .stock-adjustment .adjustment-list-section .adjustment-list .inventory-list-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 40px;
      padding-left: 10px;
      padding-right: 1rem; }
      .stock-adjustment .adjustment-list-section .adjustment-list .inventory-list-scroll ::-webkit-scrollbar {
        background: transparent; }
      @media (min-width: 1025px) {
        .stock-adjustment .adjustment-list-section .adjustment-list .inventory-list-scroll {
          padding-left: 20px; } }
      .stock-adjustment .adjustment-list-section .adjustment-list .inventory-list-scroll .active {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px 0px, 0px 6px 4px 0px #66cc23 !important; }
      .stock-adjustment .adjustment-list-section .adjustment-list .inventory-list-scroll .order-card {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 10px;
        padding-right: 10px;
        height: 100%;
        border-radius: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position: relative; }

.stock-adjustment .adjustment-form {
  height: 750px; }
  @media (max-width: 767px) {
    .stock-adjustment .adjustment-form {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .stock-adjustment .adjustment-form {
      padding-left: 10px !important; } }
  .stock-adjustment .adjustment-form hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-right: 1rem; }
  .stock-adjustment .adjustment-form .adjustment-form-content {
    padding-right: 10px;
    min-height: 100px;
    height: 100%;
    max-height: 386.5px;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: scroll; }

.edit-state {
  background-color: #ffad00 !important;
  color: #fff !important;
  border-radius: 6px; }

.active-status {
  background-color: #66cc23;
  color: #fff; }

.default-status {
  background-color: #878787;
  color: #fff; }
