.my-stock-form .stock-component .stock-form-box {
  width: 60%;
  margin: auto;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 0 30px #eee;
  box-shadow: 0 0 30px #eee;
  padding: 20px;
  border-radius: 10px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .my-stock-form .stock-component .stock-form-box {
      width: 75%; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .my-stock-form .stock-component .stock-form-box {
      width: 80%; } }
  @media screen and (max-width: 480px) {
    .my-stock-form .stock-component .stock-form-box {
      width: 100%; } }
