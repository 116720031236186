@media screen and (max-width: 480px) {
  .my-inventory .inventory-statistics .statistic {
    padding: 10px 0px; } }

.my-inventory .inventory-statistics .statistic-value {
  color: green; }
  @media screen and (max-width: 480px) {
    .my-inventory .inventory-statistics .statistic-value {
      font-size: 2.4rem !important; } }

@media screen and (max-width: 480px) {
  .my-inventory .inventory-Button {
    width: 100%;
    text-align: center; } }
