.public_footer {
  position: absolute;
  z-index: 11;
  background: #0c0d0e;
  color: #ffffff;
  width: 100%;
  min-height: 350px;
  padding: 0px 20px; }
  .public_footer a {
    color: #ffffff;
    font-size: 14px;
    font-weight: lighter;
    line-height: 30px; }
  .public_footer .boa-logo-menu {
    width: 110px;
    height: 54px; }
  .public_footer .footer-body {
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 1300px;
    padding: 70px 0px 28px 0px;
    margin: 0 auto !important;
    border-bottom: 0.5px solid #3e3f40; }
  .public_footer .content-max-width {
    display: flex;
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 1300px;
    padding: 25px 0px !important;
    margin: 0 auto; }
    .public_footer .content-max-width p.copyright {
      margin-bottom: 0px;
      margin-right: auto;
      width: 80%;
      opacity: 0.5;
      color: #ffffff;
      font-family: "Arial";
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.2px;
      position: relative;
      top: 0; }
      @media (max-width: 1025px) {
        .public_footer .content-max-width p.copyright {
          width: 50%; } }
      .public_footer .content-max-width p.copyright span.block {
        font-size: 14px;
        font-family: "calibre-regular", sans-serif;
        position: relative;
        top: 1px; }
    .public_footer .content-max-width .attribution {
      clear: both;
      margin-left: auto;
      width: 20%; }
      @media (max-width: 1025px) {
        .public_footer .content-max-width .attribution {
          width: 70%; } }
      .public_footer .content-max-width .attribution .icon {
        float: right;
        margin-left: 10px; }
      .public_footer .content-max-width .attribution img {
        position: relative;
        top: -5px;
        width: 34px;
        height: 34px; }
  .public_footer .column {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  @media screen and (max-width: 480px) {
    .public_footer {
      padding: 0px 1rem 0px 1rem; } }
  .public_footer .redirect-link p {
    cursor: pointer; }
    .public_footer .redirect-link p:hover {
      color: #00f000; }
  .public_footer .footer-copyright {
    text-align: center; }
    .public_footer .footer-copyright p {
      margin: 0px auto; }
  .public_footer .contact-details {
    margin: 10px; }

.current-country {
  border-bottom: 0.5px solid #ccc !important;
  line-height: 11px !important;
  letter-spacing: 0.2px; }
