.address-detail-card .default {
  font-size: 0.875em;
  background: #fff;
  color: #00001e;
  font-weight: bold; }

.address-detail-card .content {
  padding: 5px;
  margin-bottom: 20px;
  margin-top: 10px; }

.address-detail-card .options {
  margin: 0px auto;
  position: absolute;
  bottom: 0px;
  padding: 5px; }
  .address-detail-card .options .address-options {
    margin-top: 10px;
    font-size: 0.75em; }
    .address-detail-card .options .address-options .edit {
      margin: 5px;
      padding: 5px;
      cursor: pointer;
      color: #00001e; }
    .address-detail-card .options .address-options .delete {
      margin: 5px;
      color: red;
      cursor: pointer; }
    .address-detail-card .options .address-options .make-default {
      color: green;
      margin: 5px;
      cursor: pointer; }
    .address-detail-card .options .address-options .edit-contact {
      color: green;
      margin: 5px;
      cursor: pointer; }
  .address-detail-card .options .add-new {
    padding: 10px;
    cursor: pointer; }
