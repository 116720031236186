.main {
  margin-top: -1px;
  padding-top: 130px;
  min-height: 58vh;
  background: #ffffff;
}

.slick-dots {
  bottom: 25px;
}

.slick-active button::before {
  opacity: 1 !important;
}

.slick-dots li button::before {
  opacity: 0.5;
  color: #fff !important;
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .slick-dots {
    bottom: 5px;
  }
  .slick-dots li button::before {
    opacity: 0.5;
    color: #fff !important;
    font-size: 6px;
  }
}

@media screen and (max-width: 1500px) and (max-height: 850px) {
  body {
    zoom: 85%;
  }
}

@media screen and (max-height: 670px) {
  body {
    zoom: 78%;
  }
}

.edit-status {
  background-color: #ffad00 !important;
  color: #fff !important;
}

.edit-status:hover {
  background-color: #e69c00 !important;
  color: #fff !important;
}

.active-status {
  background-color: #66cc23 !important;
  color: #fff !important;
}

.active-status:hover {
  background-color: #5cb820 !important;
  color: #fff;
}

.default-status {
  background-color: #898989 !important;
  color: #fff !important;
}

.default-status:hover {
  background-color: #949494 !important;
  color: #fff !important;
}
