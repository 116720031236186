.confirm-order .total-price {
  width: 30%;
  float: right;
  padding: 10px;
  background: #eee;
  border: 1px solid #eee;
  border-radius: 3px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .confirm-order .total-price {
      width: 47%; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .confirm-order .total-price {
      width: 52%; } }
  @media screen and (max-width: 480px) {
    .confirm-order .total-price {
      width: 100%; } }

.confirm-order .linkToPage {
  font-size: 0.8em;
  padding-left: 20px;
  cursor: pointer; }

.confirm-order .change-address {
  font-size: 0.8em;
  padding-left: 20px;
  cursor: pointer; }
  @media screen and (max-width: 480px) {
    .confirm-order .change-address {
      display: block; } }

.confirm-order .customer-order-input-width {
  width: 30% !important; }

.confirm-order .order-success-message {
  min-height: calc(100vh - 265px); }

.confirm-order .cart-go-order-history-link {
  color: green;
  font-size: 0.9375em;
  cursor: pointer; }
  .confirm-order .cart-go-order-history-link:hover {
    text-decoration: underline; }
