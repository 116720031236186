.reset-password-box {
  min-height: calc(100vh - 184px); }
  .reset-password-box .reset-password-container {
    margin: 60px auto;
    width: 300px; }
    .reset-password-box .reset-password-container .home-page-link {
      padding-top: 20px;
      display: flex;
      justify-content: center; }
    .reset-password-box .reset-password-container .password-criteria-message {
      font-size: 0.75em;
      color: #999;
      word-wrap: none; }
  .reset-password-box .reset-password-note {
    margin-left: 79px !important;
    margin-top: -22px !important; }
