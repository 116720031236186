.content-card {
    display: flex;
    flex-direction: column;
    padding: 0px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 2px solid rgb(207, 207, 207);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.content-card:hover {
    border: 0px solid;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}


.content-card:hover .content_name {
    background-color: #66CB23;
    color: white;
}

.content-card:hover .content_icon {
    border-bottom: #66CB23;
}

.content-card:hover .content_img {
    border-bottom: #66CB23;
}

.content_icon {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-bottom: 2px solid rgb(207, 207, 207);
}

.content_img {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-bottom: 2px solid rgb(207, 207, 207);
    object-fit: cover;
}

.content_name {
    display: flex;
    align-items: center;
    border-radius: 0px 0px 8px 8px;
    height: 40px;
    background-color: rgb(240, 240, 240);
}

.content_txt {
    padding-left: 10px;
    padding-top: 5px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.type-icon {
    height: 80px;
}