.my-maintenance-form .maintenance-component .maintenance-form-box {
  width: 60%;
  margin: auto;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 0 30px #eee;
  box-shadow: 0 0 30px #eee;
  padding: 20px;
  border-radius: 10px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .my-maintenance-form .maintenance-component .maintenance-form-box {
      width: 75%; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .my-maintenance-form .maintenance-component .maintenance-form-box {
      width: 80%; } }
  @media screen and (max-width: 480px) {
    .my-maintenance-form .maintenance-component .maintenance-form-box {
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .my-maintenance-form .maintenance-component .maintenance-form-box .maintenance-form {
      padding: 0px; } }
  .my-maintenance-form .maintenance-component .maintenance-form-box .maintenance-form .check-points .header {
    padding-bottom: 6px; }
    @media screen and (max-width: 480px) {
      .my-maintenance-form .maintenance-component .maintenance-form-box .maintenance-form .check-points .header {
        padding: 0px; } }
  .my-maintenance-form .maintenance-component .maintenance-form-box .maintenance-form .check-points .section {
    margin-bottom: 0px;
    height: 33px;
    margin-right: 4px; }
  .my-maintenance-form .maintenance-component .maintenance-form-box .maintenance-form .check-points .form-panel {
    border-top: 1px solid lightgray; }
    .my-maintenance-form .maintenance-component .maintenance-form-box .maintenance-form .check-points .form-panel .add-button {
      padding-bottom: 0px;
      margin-top: auto;
      margin-bottom: 18px; }

.my-maintenance-form .maintenance-component .choose-file {
  width: 35%;
  border: 1px solid lightgrey; }

.my-maintenance-form .maintenance-component .choose-file-new {
  border: solid;
  padding: 2%; }
