p {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.assembly_part {
    border: solid 2px #66cb23;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    margin-bottom: 20px;
}

.data_row {
    display: flex;
    justify-content: space-between;
    /* background-color: ; */
}

.detail_btn {
    font-size: 14px;
    margin-top: 10px;
    padding: 5px 20px;
    background-color: #66cb23;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.detail_btn:hover {
    background-color: #4caf50;
}