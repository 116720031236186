.rs23 > .Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #66cc23;
  color: white;
  font-size: 15px;
  width: 660px;
  border-radius: 8px; }
  .rs23 > .Collapsible__trigger:after {
    cursor: pointer !important;
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .rs23 > .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .rs23 > .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.ui.tiny.modal {
  margin-left: -15.5% !important; }

.hidden-button {
  position: relative;
  display: flex;
  /* Use flexbox */
  justify-content: center;
  margin-top: -62px;
  padding-left: 4px; }

.text-container {
  width: 120px;
  /* Width of the div */
  max-height: 3em;
  /* Maximum height of the div */
  line-height: 1.5em;
  /* Line height to control the number of lines */
  overflow: hidden;
  /* Hide the overflow content */
  display: -webkit-box;
  /* Use flexbox model for multiline ellipsis */
  -webkit-box-orient: vertical;
  /* Set the orientation of the flexbox to vertical */
  -webkit-line-clamp: 2;
  /* Number of lines to show before ellipsis */
  text-overflow: ellipsis;
  /* Add ellipsis to the overflow text */
  word-wrap: break-word; }
