.inventory-detail .inventory-title {
  margin-left: -16px;
  margin-right: -16px;
  text-transform: uppercase; }
  .inventory-detail .inventory-title .add-stock {
    text-transform: capitalize;
    text-align: right;
    font-size: 0.9em;
    cursor: pointer; }
    .inventory-detail .inventory-title .add-stock:hover {
      color: green;
      text-decoration: underline; }

@media (min-width: 481px) and (max-width: 748px) {
  .inventory-detail .inventory-body .details .column {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (max-width: 480px) {
  .inventory-detail .inventory-body .details .column {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

.inventory-detail .inventory-body .action-button {
  width: 88%;
  margin: 2px auto; }

.inventory-detail .link-button {
  cursor: pointer;
  color: blue; }
  .inventory-detail .link-button:hover {
    text-decoration: underline; }

.inventory-detail .cart-product-image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 75% !important;
  background-color: transparent; }
  @media screen and (max-width: 480px) {
    .inventory-detail .cart-product-image {
      height: 100px;
      width: 100px; } }

.cart-go-search-link {
  cursor: pointer; }
