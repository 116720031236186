.review-maintenance-form .maintenance-component .maintenance-form-box {
  width: 70%;
  margin: auto;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 0 30px #eee;
  box-shadow: 0 0 30px #eee;
  padding: 20px;
  border-radius: 10px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .review-maintenance-form .maintenance-component .maintenance-form-box {
      width: 85%; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .review-maintenance-form .maintenance-component .maintenance-form-box {
      width: 9 0%; } }
  @media screen and (max-width: 480px) {
    .review-maintenance-form .maintenance-component .maintenance-form-box {
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .review-maintenance-form .maintenance-component .maintenance-form-box .check-points {
      padding: 0px; } }
  .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .header {
    padding-bottom: 6px; }
  .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .check-result .radio {
    padding-left: 10px; }
  .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .check-result label {
    text-transform: capitalize; }
  .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .check-result .red label {
    color: red; }
  .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .check-result .orange label {
    color: #dea54b; }
  .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .check-result .green label {
    color: green; }
  .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .form-panel {
    border-top: 1px solid lightgray; }
    .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .form-panel .point-header::first-letter {
      text-transform: capitalize; }
    .review-maintenance-form .maintenance-component .maintenance-form-box .check-points .form-panel .add-button {
      padding-bottom: 0px;
      margin-top: auto;
      margin-bottom: 18px; }
  @media screen and (max-width: 480px) {
    .review-maintenance-form .maintenance-component .maintenance-form-box .button-float {
      width: 100%; } }

.review-maintenance-form .maintenance-component .HelpText_checkList {
  cursor: pointer;
  font-size: smaller;
  margin-left: -5% !important; }
