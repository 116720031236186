.profile-details {
  width: 70%;
  margin: 10px auto;
  border: 1px solid #eee;
  box-shadow: 0 0 30px #eee;
  border-radius: 10px;
  padding: 20px; }
  .profile-details .profile-field-value {
    margin-left: 30px; }
  .profile-details .profile-field-key {
    width: 100%;
    font-weight: bold; }
  .profile-details p {
    padding-top: 10px;
    padding-bottom: 10px; }
  .profile-details .error-message {
    color: red; }
  .profile-details .button-float {
    float: right;
    margin-right: 0px !important;
    margin-left: 10px; }
  .profile-details .title {
    display: flex;
    flex-direction: row; }
    @media (min-width: 481px) and (max-width: 748px) {
      .profile-details .title {
        display: block; } }
    @media screen and (max-width: 480px) {
      .profile-details .title {
        display: block; } }
    .profile-details .title h3 {
      width: 50%;
      min-width: 200px; }
    .profile-details .title .changePassword {
      width: 50%;
      text-align: right; }
      @media (min-width: 481px) and (max-width: 748px) {
        .profile-details .title .changePassword {
          width: 100%; } }
      @media screen and (max-width: 480px) {
        .profile-details .title .changePassword {
          width: 100%; } }
  @media screen and (max-width: 414px) {
    .profile-details {
      width: 100% !important;
      padding: 20px; } }
  @media screen and (max-width: 375px) {
    .profile-details {
      width: 100% !important;
      padding: 10px; } }
  @media screen and (max-width: 320px) {
    .profile-details {
      width: 100% !important;
      padding: 10px; } }
