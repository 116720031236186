.button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.ui.form textarea {
    min-height: 150px;
    /* Adjust the height as needed */
}

.ui.form .field.error textarea {
    border-color: red !important;
}