.my-asset-info-form .asset-component .asset-form-box {
  width: 80%;
  margin: auto;
  padding: 20px;
  border-radius: 10px; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .my-asset-info-form .asset-component .asset-form-box {
      width: 75%; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .my-asset-info-form .asset-component .asset-form-box {
      width: 80%; } }
  @media screen and (max-width: 480px) {
    .my-asset-info-form .asset-component .asset-form-box {
      width: 100%; } }
  .my-asset-info-form .asset-component .asset-form-box .form-body .form-input label {
    font-weight: bold; }
