.crew-list .crew-title {
  margin-left: -16px;
  margin-right: -16px; }
  .crew-list .crew-title .add-member {
    font-size: 0.8em;
    cursor: pointer; }
    .crew-list .crew-title .add-member:hover {
      color: green;
      text-decoration: underline; }

.crew-list .crew-member .action-button {
  color: red;
  font-size: 0.8em;
  padding-left: 8px;
  cursor: pointer; }
  .crew-list .crew-member .action-button:hover {
    text-decoration: underline; }

.crew-list .crew-member .attach-stock {
  padding-top: 51px; }

.cart-go-search-link {
  cursor: pointer; }
