.card-product-type {
  margin-bottom: 2px;
  color: gray; }

.card-product-rrp {
  margin-bottom: 0;
  color: green;
  font-weight: bolder; }

.card-product-buy {
  margin-bottom: 0;
  color: #060661;
  font-weight: bolder; }

.card-product-callboa {
  margin-bottom: 0;
  color: red; }

.cart-products .cart-go-search-link {
  color: green;
  font-size: 0.93em;
  cursor: pointer; }
  .cart-products .cart-go-search-link:hover {
    text-decoration: underline; }

.cart-products .cart-product-card {
  padding-top: 10px;
  padding-bottom: 10px; }
  .cart-products .cart-product-card .ui.checkbox input:checked ~ label:before {
    border: 1.5px solid #000;
    border-radius: 50% !important;
    background-color: #66cc23 !important;
    color: #66cc23 !important; }
  .cart-products .cart-product-card .ui.checkbox input:checked ~ label::after {
    border: 1.5px solid #000;
    border-radius: 50% !important;
    background-color: #66cc23 !important;
    color: #66cc23 !important; }
  .cart-products .cart-product-card .ui.checkbox label:before {
    border: 1.5px solid #000;
    border-radius: 50% !important;
    background-color: transparent !important; }
  .cart-products .cart-product-card .discount {
    font-size: 13px;
    color: green;
    padding-bottom: 10px; }
    .cart-products .cart-product-card .discount .label {
      margin: 3px !important; }
  .cart-products .cart-product-card .cart-product-image {
    margin-top: 21px; }
    @media (min-width: 480px) and (max-width: 1024px) {
      .cart-products .cart-product-card .cart-product-image {
        margin-top: 28px;
        height: 37px;
        width: 43px; } }
    @media screen and (max-width: 480px) {
      .cart-products .cart-product-card .cart-product-image {
        height: 25px;
        width: 25px; } }
  .cart-products .cart-product-card .cart-update-remove-link {
    color: red;
    font-size: 1em;
    margin-top: 10px;
    cursor: pointer; }
    .cart-products .cart-product-card .cart-update-remove-link:hover {
      text-decoration: underline; }
  .cart-products .cart-product-card .cart-edit-description {
    font-size: 0.75em;
    padding: 5px;
    cursor: pointer; }
    .cart-products .cart-product-card .cart-edit-description:hover {
      text-decoration: underline; }
  .cart-products .cart-product-card .cart-product-price {
    padding-top: 15px; }
  .cart-products .cart-product-card .product-header {
    max-width: 91%;
    word-wrap: break-word;
    margin-top: -32px !important; }
    .cart-products .cart-product-card .product-header .card-meta {
      font-weight: normal;
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.4); }
  .cart-products .cart-product-card .cart-update-quantity-link {
    color: green;
    font-size: 0.87em;
    cursor: pointer; }
    .cart-products .cart-product-card .cart-update-quantity-link:hover {
      text-decoration: underline; }
  .cart-products .cart-product-card .quantity-form {
    margin-top: 5px; }
    .cart-products .cart-product-card .quantity-form .quantity-label {
      margin-top: 6px;
      text-align: center;
      font-weight: bold; }
    .cart-products .cart-product-card .quantity-form .quantity-width {
      margin-top: 5px;
      width: 100%; }
  @media (min-width: 481px) and (max-width: 748px) {
    .cart-products .cart-product-card .remove-button {
      display: none; } }
  @media screen and (max-width: 480px) {
    .cart-products .cart-product-card .remove-button {
      display: none; } }
  .cart-products .cart-product-card .remove-button-mobile {
    display: none; }
    @media (min-width: 481px) and (max-width: 748px) {
      .cart-products .cart-product-card .remove-button-mobile {
        display: block;
        position: absolute;
        right: 19px; } }
    @media screen and (max-width: 480px) {
      .cart-products .cart-product-card .remove-button-mobile {
        display: block;
        position: absolute;
        right: 19px; } }
