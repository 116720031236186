.boapod-list-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 40px; }
  .boapod-list-scroll ::-webkit-scrollbar {
    background: transparent; }
  .boapod-list-scroll .active {
    box-shadow: #66cc23 0px 0px 0px 2px, #66cc23 0px 4px 6px -1px, #66cc23 0px 0px 0px inset; }

.marker {
  z-index: 1000; }

.boapod-container .popup {
  margin-top: -100px;
  margin-bottom: 20px;
  background-color: #ffff;
  height: 120px !important;
  width: 320px !important;
  border: 3px solid #66cc23;
  border-radius: 10px;
  z-index: 1001; }

.boapod-container .popup:after {
  content: "";
  position: absolute;
  top: 18%;
  left: 45%;
  width: 0;
  height: 0;
  border-top: solid 15px #66cc23;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
  z-index: -1; }

.full-screen-div {
  position: fixed;
  top: 109px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 10; }

.full-screen-div2 {
  position: fixed;
  top: 200px;
  left: 10vw;
  width: 22vw;
  min-width: 470px;
  max-width: 470px;
  height: 85vh;
  z-index: 11; }

.full-screen-div3 {
  position: fixed;
  top: 78.5vh;
  left: -0.5vw;
  width: 90vw;
  height: 100%;
  background: none;
  z-index: 11; }

.full-screen-div4 {
  position: fixed;
  top: 71vh;
  left: 88vw;
  width: 100%;
  height: 5vh;
  background: none;
  z-index: 13; }

.full-screen-div5 {
  position: fixed;
  top: 109px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 12; }
