.schedule-container .section-menu .item {
  border: 1px solid lightgray !important;
  text-transform: uppercase; }

.schedule-container .section-menu .active {
  box-shadow: 1px 1px 10px 1px lightgrey !important; }

.schedule-container .schedule-info {
  margin: auto -37px auto 16px; }
  @media screen and (max-width: 480px) {
    .schedule-container .schedule-info {
      margin: initial; } }

.schedule-container .show-category {
  font-size: 1.1em;
  font-weight: bolder;
  padding-bottom: 0px !important;
  padding-top: 5px !important; }
