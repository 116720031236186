.search_area {
    display: inline-flex;
    /* justify-content: space-between; */
    margin-bottom: 20px;
}

.category_label {
    display: block;
    font-size: 24px;
    font-weight: 600;
}

.part_no_input {
    width: 300px;
    height: 40px;
    border: 2px solid #cacaca;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    outline: none;
}

.part_no_input:focus {
    border-color: #66cb23;
}

.component_search_button {
    margin: 0;
    width: 100px;
    height: 40px;
    background-color: #66cb23;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search_button:hover {
    background-color: #4caf50;
}

.h_divider {
    width: 100%;
    height: 2px;
    background-color: #66cb23;
    margin: 20px 0;
}

.component_title {
    font-size: 24px;
    font-weight: 600;
    color: black;
}