.create-account {
  margin-bottom: 30px !important; }
  .create-account .verify-customer-button {
    max-width: 300px;
    margin: 10px auto !important; }
  .create-account .sign-up-header-title {
    margin: 10px auto;
    font-size: 1.25em; }
  .create-account .create-account-form {
    min-height: calc(100vh - 295px);
    max-width: 650px;
    padding: 30px !important;
    border: 1px solid #eee;
    border-radius: 10px;
    width: 60% !important;
    margin: 10px auto; }
    .create-account .create-account-form .password-criteria-message {
      font-size: 0.75em;
      color: #999;
      word-wrap: none; }
  .create-account .customer-type-select {
    width: 90% !important; }
  .create-account .right-float {
    float: right; }
  .create-account .error-message {
    color: red; }
  .create-account .recaptcha-margin {
    margin-top: 20px; }
  @media screen and (max-width: 360px) {
    .create-account .create-account-form {
      padding: 5px !important; }
    .create-account .right-float {
      float: none; }
    .create-account .sign-up-header-title {
      margin: 10px auto;
      font-size: 0.93em;
      text-align: center; } }
  @media screen and (max-width: 414px) {
    .create-account .create-account-form {
      padding: 5px !important; }
    .create-account .right-float {
      float: none; }
    .create-account .sign-up-header-title {
      margin: 10px auto;
      font-size: 0.93em;
      text-align: center; } }
  @media screen and (max-width: 320px) {
    .create-account .create-account-form {
      padding: 5px !important; }
    .create-account .right-float {
      float: none; }
    .create-account .sign-up-header-title {
      margin: 10px auto;
      font-size: 0.93em;
      text-align: center; } }

.create-account-verification-message {
  text-align: center;
  margin: 10px auto;
  margin-top: 50px;
  min-height: calc(100vh - 326px); }

.validationNote {
  margin-left: -119px !important;
  margin-top: 18px !important; }
