@font-face {
  font-family: 'boaicon';
  src:  url('boaicon.eot?wgad9s');
  src:  url('boaicon.eot?wgad9s#iefix') format('embedded-opentype'),
    url('boaicon.ttf?wgad9s') format('truetype'),
    url('boaicon.woff?wgad9s') format('woff'),
    url('boaicon.svg?wgad9s#boaicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ba-"], [class*=" ba-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'boaicon' !important;
 /*speak: never;*/
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ba-plus:before {
  content: "\e808";
}
.ba-edit:before {
  content: "\e807";
}
.ba-close_circle:before {
  content: "\e906";
}
.ba-camera3:before {
  content: "\e806";
}
.ba-filter:before {
  content: "\e907";
}
.ba-close:before {
  content: "\e908";
}
.ba-chat_b:before {
  content: "\e901";
}
.ba-camera:before {
  content: "\e905";
}
.ba-dots:before {
  content: "\e902";
}
.ba-add_user:before {
  content: "\e903";
}
.ba-add_group:before {
  content: "\e904";
}
.ba-search:before {
  content: "\e900";
}
.ba-user_group:before {
  content: "\e805";
}
.ba-mic:before {
  content: "\e804";
}
.ba-paperclip:before {
  content: "\e803";
}
.ba-product:before {
  content: "\e802";
}
.ba-send:before {
  content: "\e801";
}
.ba-single_user:before {
  content: "\e800";
}
