.asset-list {
  margin-bottom: 10px !important; }
  .asset-list .asset-title {
    margin-left: -16px;
    margin-right: -16px; }
  .asset-list .asset-detail {
    padding: 6px !important; }
    @media (min-width: 481px) and (max-width: 748px) {
      .asset-list .asset-detail .asset-body .asset-info .column {
        padding-left: 0px !important;
        padding-right: 0px !important; } }
    @media screen and (max-width: 480px) {
      .asset-list .asset-detail .asset-body .asset-info .column {
        padding-left: 0px !important;
        padding-right: 0px !important; } }
  .asset-list .manage-asset {
    border-left: 1px solid lightgreen; }
    @media screen and (max-width: 480px) {
      .asset-list .manage-asset {
        border-left: none; } }
    @media (min-width: 481px) and (max-width: 748px) {
      .asset-list .manage-asset .column {
        padding-left: 0px !important;
        padding-right: 0px !important; } }
    @media screen and (max-width: 480px) {
      .asset-list .manage-asset .column {
        padding-left: 0px !important;
        padding-right: 0px !important; } }
    .asset-list .manage-asset .action-button {
      color: red;
      font-size: 0.8em;
      padding-left: 8px;
      cursor: pointer; }
      .asset-list .manage-asset .action-button:hover {
        text-decoration: underline; }
    .asset-list .manage-asset .attach-stock {
      padding-top: 51px;
      padding-left: 0px !important;
      padding-right: 0px !important; }
      @media screen and (max-width: 480px) {
        .asset-list .manage-asset .attach-stock {
          padding-top: 0px; } }

.cart-go-search-link {
  cursor: pointer; }

.asset-detail-button {
  cursor: pointer; }
  .asset-detail-button:hover {
    text-decoration: underline; }
