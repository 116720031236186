.model_input {
    width: 300px;
    height: 40px;
    border: 2px solid #cacaca;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    outline: none;
    /* margin-bottom: 20px; */
    /* Remove default focus outline */
}

.model_input:focus {
    border-color: #66cb23;
    /* Set focus border color as green */
}

.arrangement_label {
    display: block;
    font-size: 20px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
}

.arrangement_list {
    padding: 10px;
    overflow-y: auto;
    border: solid 2px #66cb23;
    border-radius: 10px;
    max-height: 500px;
    width: 350px;
    scrollbar-width: auto;
    scrollbar-color: #66cb23 #f1f1f1;
}

.selected_item {
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 10px;
    background-color: #66cb23;
    font-size: 18px;
    color: white;
}

.normal_item {
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 10px;
    background-color: #66cb23;
    font-size: 18px;
    background-color: white;
    color: black;
}

.assembly_list {
    overflow-y: auto;
    max-height: 500px;
    width: 350px;
    scrollbar-width: auto;
    scrollbar-color: #66cb23 #f1f1f1;
}

.vertical-divider {
    width: 1px;
    /* Thin line width */
    height: 100%;
    /* Adjust based on content height */
    background-color: #ccc;
    /* Grey line */
    margin-left: 50px;
    margin-right: 50px;
    /* Space on either side of the divider */
}

.no_result {
    font-size: 24px;
    color: rgb(255, 117, 117);
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
}