hr {
  height: 1px !important;
  background-color: #ccc;
  border: none;
  margin-left: 0px;
  margin-right: 1rem; }

.custom-checkbox input[type="checkbox"] + label::before {
  border: 2px solid #66cc23;
  /* Set the border color as desired */
  color: #fff !important; }

/* Style the checkmark */
.custom-checkbox input[type="checkbox"]:checked + label::before {
  color: #fff !important;
  background-color: #66cc23;
  /* Set the background color of the checkmark */
  border-color: #66cc23;
  /* Set the border color of the checkmark */ }

/* Change the color of the checkmark's inner circle */
.custom-checkbox input[type="checkbox"]:checked + label::after {
  background-color: #66cc23;
  /* Set the background color of the checkmark's inner circle */
  color: #fff !important; }

.remove-bom {
  width: 400px !important; }

.hosemaker-modal {
  width: 1380px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: static !important;
  margin-top: 120px !important; }

.other-components-modal {
  width: 900px !important;
  min-height: 448.1px !important;
  max-height: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: static !important;
  margin-top: 120px !important;
  overflow: scroll; }

.results {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 370px; }

.order-management ::-webkit-scrollbar {
  width: 10px !important; }

.order-management ::-webkit-scrollbar-thumb {
  background: #66cc23; }

.order-management ::-webkit-scrollbar-thumb:hover {
  background: #66cc23; }

.order-management .error input {
  border-color: #cc181e !important; }

.order-management .error {
  border-color: #cc181e !important; }

.order-management .select-error {
  border-color: #cc181e !important; }

.order-management div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 1024px) {
  .order-management .column {
    padding-left: 0px !important; } }

.order-management .ui.labeled.icon.button > .icon,
.order-management .ui.labeled.icon.buttons > .button > .icon {
  color: #66cc23 !important; }

.order-management .inventory-list-section {
  height: 730px; }
  .order-management .inventory-list-section hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-left: 0px;
    margin-right: 1rem; }
    @media (min-width: 1025px) {
      .order-management .inventory-list-section hr {
        margin-left: 20px; } }
  .order-management .inventory-list-section .order-header {
    height: 45px;
    padding-left: 10px;
    padding-right: 1rem; }
    @media (min-width: 1025px) {
      .order-management .inventory-list-section .order-header {
        padding-left: 20px; } }
  .order-management .inventory-list-section .order-status {
    padding-bottom: 10px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 1rem; }
    .order-management .inventory-list-section .order-status button {
      padding: 8px;
      margin-top: 5px; }
    .order-management .inventory-list-section .order-status .active-status {
      background-color: #66cc23;
      color: #fff;
      max-width: 292px; }
    @media (min-width: 1025px) {
      .order-management .inventory-list-section .order-status {
        padding-left: 20px; } }
  .order-management .inventory-list-section .order-list {
    height: 600px; }
    .order-management .inventory-list-section .order-list .inventory-list-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 40px;
      padding-left: 10px;
      padding-right: 1rem; }
      .order-management .inventory-list-section .order-list .inventory-list-scroll ::-webkit-scrollbar {
        background: transparent; }
      @media (min-width: 1025px) {
        .order-management .inventory-list-section .order-list .inventory-list-scroll {
          padding-left: 20px; } }
      .order-management .inventory-list-section .order-list .inventory-list-scroll .active {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px 0px, 0px 6px 4px 0px #66cc23 !important; }
      .order-management .inventory-list-section .order-list .inventory-list-scroll .order-card {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 10px;
        padding-right: 10px;
        height: 100%;
        border-radius: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position: relative; }
        .order-management .inventory-list-section .order-list .inventory-list-scroll .order-card .content {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          padding-left: 10px !important; }
          @media (max-width: 1024px) {
            .order-management .inventory-list-section .order-list .inventory-list-scroll .order-card .content {
              padding-right: 0px; } }
        .order-management .inventory-list-section .order-list .inventory-list-scroll .order-card .description {
          font-size: 14px !important; }
        .order-management .inventory-list-section .order-list .inventory-list-scroll .order-card .order-state {
          min-height: 117px;
          padding-top: 75px;
          white-space: nowrap; }
          .order-management .inventory-list-section .order-list .inventory-list-scroll .order-card .order-state .order-state-text {
            text-align: center;
            font-size: 11px;
            -webkit-transform: rotate(-90deg); }

.order-management .inventory-form {
  height: 750px; }
  @media (max-width: 767px) {
    .order-management .inventory-form {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .order-management .inventory-form {
      padding-left: 10px !important; } }
  .order-management .inventory-form .order-actions {
    height: 45px;
    padding-right: 1rem; }
    .order-management .inventory-form .order-actions button {
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px; }
      @media (max-width: 1300px) {
        .order-management .inventory-form .order-actions button {
          font-size: 1vw !important; } }
    .order-management .inventory-form .order-actions .active-status {
      background-color: #66cc23;
      color: #fff; }
  .order-management .inventory-form hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-right: 1rem; }
  .order-management .inventory-form .order-form-content {
    padding-right: 10px;
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: scroll; }
    .order-management .inventory-form .order-form-content .arrow-steps .step {
      font-size: 14px;
      text-align: center;
      color: #0c0d0e;
      cursor: default;
      margin: 0 1px 0 0;
      padding: 10px 0px 10px 0px;
      float: left;
      position: relative;
      background-color: #e3e6ea;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      min-width: 98px; }
    .order-management .inventory-form .order-form-content .arrow-steps .step a {
      color: #0c0d0e;
      text-decoration: none;
      cursor: pointer; }
    .order-management .inventory-form .order-form-content .arrow-steps .step:after,
    .order-management .inventory-form .order-form-content .arrow-steps .step:before {
      content: "";
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
      border-left: 17px solid #e3e6ea;
      z-index: 2; }
    .order-management .inventory-form .order-form-content .arrow-steps .step:before {
      right: auto;
      left: 0;
      border-left: 17px solid #fff;
      z-index: 0; }
    .order-management .inventory-form .order-form-content .arrow-steps .step:first-child:before {
      border: none; }
    .order-management .inventory-form .order-form-content .arrow-steps .step:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .order-management .inventory-form .order-form-content .arrow-steps .step span {
      position: relative; }
    .order-management .inventory-form .order-form-content *.arrow-steps .step.done span:before {
      opacity: 1;
      content: "";
      position: absolute;
      top: -2px;
      left: -10px;
      font-size: 11px;
      line-height: 21px; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.current {
      color: #fff;
      background-color: #66cc23; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.current a {
      color: #fff;
      text-decoration: none; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.current:after {
      border-left: 17px solid #66cc23; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.done {
      color: #0c0d0e;
      background-color: #ced4db; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.done a {
      color: #0c0d0e;
      text-decoration: none; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.done:after {
      border-left: 17px solid #ced4db; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.archived {
      color: #ced4db;
      background-color: #dd4b39; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.archived:before {
      border: none !important; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.archived a {
      color: #fff;
      text-decoration: none; }
    .order-management .inventory-form .order-form-content .arrow-steps .step.archived:after {
      border-left: 17px solid #dd4b39; }

.order-management .inventory-info {
  padding-right: 10px;
  height: 780px;
  overflow-x: hidden;
  overflow-y: scroll; }
  @media (max-width: 767px) {
    .order-management .inventory-info {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .order-management .inventory-info {
      padding-left: 10px !important; } }
  .order-management .inventory-info .active-status {
    background-color: #66cc23;
    color: #fff; }

.order-management .order-card {
  height: 100%;
  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative; }

.edit-state {
  background-color: #ffad00 !important;
  color: #fff !important;
  border-radius: 6px; }

.active-status {
  background-color: #66cc23;
  color: #fff; }

.default-status {
  background-color: #878787;
  color: #fff; }
