.my-hoses .my-favorite-card {
  padding: 10px 0px; }

.my-hoses .add-to-cart .content {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.my-hoses .last-bought {
  font-size: 0.9em;
  font-style: italic;
  color: grey; }
