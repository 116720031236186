.image-list .issue-images {
  width: 65px !important;
  height: 59px !important;
  padding-top: 5px; }

.image-list .delete-icon {
  font-size: 13px;
  position: relative;
  bottom: 30px;
  right: 11px;
  border: 1px solid lightgray;
  border-radius: 50%;
  padding: 1px 1px 4px 2px;
  height: 23px;
  width: 23px;
  background: red;
  cursor: pointer;
  color: white; }
