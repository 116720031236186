.account-details {
  width: 70%;
  margin: 10px auto;
  min-height: calc(100vh - 363px);
  border: 1px solid #eee;
  box-shadow: 0 0 30px #eee;
  border-radius: 10px;
  padding: 20px; }
  .account-details .account-field-value {
    margin-left: 30px; }
  .account-details .account-field-key {
    font-weight: bold; }
  .account-details .apply-boa-credit {
    cursor: pointer; }
  .account-details .button-float {
    float: right; }
  .account-details .default {
    font-size: 0.8em;
    color: grey; }
  .account-details .boa-credit-status {
    text-align: center;
    background: #00f000; }
  @media screen and (max-width: 480px) {
    .account-details {
      width: 100% !important; } }
  .account-details .address-grid {
    width: 100%;
    margin: 10px auto !important; }
    .account-details .address-grid .address-column {
      border: 1px solid #eee;
      margin: 5px;
      box-shadow: 0 0 10px #eee; }
    .account-details .address-grid .primary {
      border-top: 2px solid #66cc23 !important;
      border-radius: 3px 3px 0px 0px; }
