.cart .ui.checkbox input:checked ~ label:before {
  border: 1.5px solid #000;
  border-radius: 50% !important;
  background-color: #66cc23 !important;
  color: #66cc23 !important; }

.cart .ui.checkbox input:checked ~ label::after {
  border: 1.5px solid #000;
  border-radius: 50% !important;
  background-color: #66cc23 !important;
  color: #66cc23 !important; }

.cart .ui.checkbox label:before {
  border: 1.5px solid #000;
  border-radius: 50% !important;
  background-color: transparent !important; }

.cart .cart-confirm-order {
  width: 100%;
  max-width: 400px;
  float: right;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 3px; }
  .cart .cart-confirm-order .info p {
    padding-top: 0px; }
    .cart .cart-confirm-order .info p span {
      font-weight: bold; }
  .cart .cart-confirm-order .button {
    margin-top: 10px; }
