* {
  font-family: "Calibre", "Lato", sans-serif; }

html {
  font-size: 100%; }

body {
  background: #0c0d0e;
  margin: 0;
  padding: 0;
  font-family: "Calibre", "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.5; }

.ui.tiny.modal {
  margin-left: -285px; }

.body-panel {
  background: #ffffff;
  min-height: calc(100vh - 52.2vh); }

button:disabled {
  opacity: 0.5; }

.ui .container {
  width: 1340px !important; }

.main-container {
  width: 1340px !important; }
  .main-container ::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */ }

.menu {
  border-radius: 0px !important;
  margin: 0 0 !important;
  border: 1px solid transparent !important;
  box-shadow: 0 1px 2px 0 transparent !important; }
  .menu .cart-count {
    color: #fff !important; }
  .menu .item {
    border: none;
    transition-property: box-shadow, border, color, transform !important;
    transition-duration: 0.3s !important; }
    .menu .item:before {
      background: transparent !important; }
    .menu .item:hover {
      color: #c3c3c3 !important; }
  .menu .header-menu-item {
    color: #fff !important; }
    .menu .header-menu-item .active {
      color: #66cc23 !important; }
  .menu .contact {
    font-size: 22px !important;
    letter-spacing: 1px;
    font-weight: bold !important;
    color: #66cc23 !important; }
    .menu .contact:hover {
      color: #66cc23 !important; }
  .menu .top-menu {
    font-size: 12px !important;
    letter-spacing: 1px;
    font-weight: 100 !important;
    color: #fff !important; }
  .menu .active {
    color: #66cc23 !important;
    background: transparent !important;
    font-weight: bold !important; }
    .menu .active:hover {
      color: #66cc23 !important; }
  .menu .custom-menu-link {
    height: 63px;
    padding: 0px 24px !important;
    min-width: 118px; }
    .menu .custom-menu-link:hover {
      border-bottom: 4px solid #66cc23 !important;
      border-radius: 0px !important;
      margin-top: -10px;
      position: inherit;
      box-shadow: 0px -5px 0px #fff !important;
      background: transparent !important; }
      @media (min-width: 481px) and (max-width: 748px) {
        .menu .custom-menu-link:hover {
          border-bottom: none !important;
          border-radius: 0px !important;
          margin-top: 0px;
          position: inherit;
          box-shadow: 0px 0px 0px 0px !important;
          background: transparent !important; } }
      @media screen and (max-width: 1000px) {
        .menu .custom-menu-link:hover {
          border-bottom: none !important;
          border-radius: 0px !important;
          margin-top: 0px;
          position: inherit;
          box-shadow: 0px 0px 0px 0px !important;
          background: transparent !important; } }
    @media (min-width: 481px) and (max-width: 748px) {
      .menu .custom-menu-link {
        height: 50px;
        padding: 0px 20px 0 4px !important;
        min-width: 100px; } }
    @media screen and (max-width: 1000px) {
      .menu .custom-menu-link {
        height: 50px;
        padding: 0px 20px 0 4px !important;
        min-width: 100px; } }
    .menu .custom-menu-link .icon {
      float: left !important;
      width: 2.18em !important; }

.profile-menu {
  padding: 0px 40px 0px 40px;
  font-size: 0.75em;
  background: #0b0d0f !important; }
  .profile-menu .item {
    padding: 0px 12px !important;
    font-size: 15px;
    font-weight: 100; }
  .profile-menu .menu .item a {
    color: #fff !important; }

.boa-logo-menu {
  position: absolute;
  left: 0;
  top: 28px;
  width: 110px;
  height: 54px;
  bottom: 5px; }

.boa-logo-menu-login {
  height: 43px !important;
  width: 101px !important;
  margin-left: 33px; }

.user-name {
  padding-left: 5px; }

.footer-panel {
  width: 100%;
  padding: 0px;
  height: 200px;
  background: primary;
  color: #fff; }

.main-menu {
  position: fixed;
  width: 100%;
  height: 110px;
  z-index: 99;
  background: #0c0d0e;
  min-width: 320px; }
  .main-menu .item {
    color: #333; }
  .main-menu .profile-menu {
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 1340px;
    padding: 0px 0px 0px 20px;
    margin: 0 auto !important; }
    @media screen and (min-width: 2400px) {
      .main-menu .profile-menu {
        max-width: 1640px; } }

.sub-menu {
  padding-top: 48px; }
  @media screen and (max-width: 1000px) {
    .sub-menu {
      padding-top: 45px; } }

.main-menu-public {
  position: fixed;
  width: 100%;
  min-height: 60px !important;
  height: 60px;
  z-index: 99;
  background: #0c0d0e;
  min-width: 320px; }
  .main-menu-public .item {
    color: #333; }
  .main-menu-public .profile-menu {
    min-height: 40px !important;
    height: 40px;
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 1340px;
    padding: 0px 0px 0px 20px;
    margin: 0 auto !important; }
    @media screen and (min-width: 2400px) {
      .main-menu-public .profile-menu {
        max-width: 1640px; } }

.cart_Link {
  position: fixed !important;
  top: 4px !important;
  right: 18px !important; }
  .cart_Link .icon {
    font-size: 1.5em; }
  .cart_Link .label {
    padding: 2px 5px !important;
    top: 1px !important;
    border-radius: 50%; }

@media (min-width: 768px) and (max-width: 1024px) {
  .ui.grid {
    margin-left: 0rem;
    margin-right: 0rem; } }

@media (min-width: 481px) and (max-width: 748px) {
  .ui.grid {
    margin-left: 0rem;
    margin-right: 0rem; } }

@media screen and (max-width: 1000px) {
  .ui.grid {
    margin-left: 0rem;
    margin-right: 0rem; } }

@media (min-width: 481px) and (max-width: 748px) {
  .no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (max-width: 1000px) {
  .no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .no-top-padded {
    padding-top: 0px !important; } }

@media (min-width: 481px) and (max-width: 748px) {
  .no-top-padded {
    padding-top: 0px !important; } }

@media screen and (max-width: 1000px) {
  .no-top-padded {
    padding-top: 0px !important; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .no-bottom-padded {
    padding-bottom: 0px !important; } }

@media (min-width: 481px) and (max-width: 748px) {
  .no-bottom-padded {
    padding-bottom: 0px !important; } }

@media screen and (max-width: 1000px) {
  .no-bottom-padded {
    padding-bottom: 0px !important; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .no-left-padded {
    padding: 0px !important; } }

@media screen and (max-width: 1000px) {
  .no-left-padded {
    padding-left: 0px !important; } }

@media (min-width: 481px) and (max-width: 748px) {
  .no-right-padded {
    padding-right: 0px !important; } }

@media screen and (max-width: 1000px) {
  .no-right-padded {
    padding-right: 0px !important; } }

.user-menu i.dropdown.icon {
  margin: 0px !important; }

@media (min-width: 481px) and (max-width: 748px) {
  .user-menu {
    display: none !important; } }

@media screen and (max-width: 1000px) {
  .user-menu {
    display: none !important; } }

.user-menu-mobile {
  display: none !important; }
  @media (min-width: 481px) and (max-width: 748px) {
    .user-menu-mobile {
      display: block !important;
      position: absolute;
      right: 4px;
      top: 58px; } }
  @media screen and (max-width: 1000px) {
    .user-menu-mobile {
      display: block !important;
      position: absolute;
      right: 4px;
      top: 58px; } }

@media screen and (max-width: 1000px) {
  .steps .step {
    padding: 1.14285714em 0px !important; } }

@media screen and (max-width: 1000px) {
  .title {
    font-size: 3.6vw; } }

.user-facility-menu {
  display: none !important; }
  @media (min-width: 481px) and (max-width: 748px) {
    .user-facility-menu {
      display: block !important; } }
  @media screen and (max-width: 1000px) {
    .user-facility-menu {
      display: block !important; } }

.facilities {
  display: block !important;
  padding: 0px 0px !important; }
  @media (min-width: 481px) and (max-width: 748px) {
    .facilities {
      display: none !important; } }
  @media screen and (max-width: 1000px) {
    .facilities {
      display: none !important; } }
  .facilities .set-icon {
    top: 25px;
    position: absolute;
    padding-right: 0px; }
  .facilities .user-menu {
    padding-left: 23px !important; }

@media screen and (max-width: 1000px) {
  .menu-item {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

.user-menu .top-menu {
  padding: 0px !important;
  border-bottom: 0.5px solid #ccc !important;
  line-height: 11px !important;
  margin-right: 12px;
  font-size: 14px !important;
  line-height: 11px;
  font-family: "calibre-regular", sans-serif;
  padding-bottom: 0;
  letter-spacing: 0.2px; }

.user-menu .top-menu-active {
  color: #ffffff !important;
  padding: 0px !important;
  border-bottom: none;
  line-height: 11px !important;
  margin-right: 12px;
  font-size: 14px !important;
  line-height: 11px;
  font-family: "calibre-regular", sans-serif;
  padding-bottom: 0;
  letter-spacing: 0.2px; }

.user-menu .top-menu-logout {
  padding: 0px !important;
  border-bottom: 0.5px solid #ccc !important;
  line-height: 11px !important;
  margin-right: 0px;
  font-size: 14px !important;
  line-height: 11px;
  font-family: "calibre-regular", sans-serif;
  padding-bottom: 0;
  letter-spacing: 0.2px;
  cursor: pointer; }
  .user-menu .top-menu-logout :hover {
    color: #c3c3c3 !important; }

.user-menu .item {
  height: 30%;
  margin-top: 60px; }
  .user-menu .item .active {
    color: #66cc23 !important; }

.user-menu i.icon {
  font-size: 22px; }
  .user-menu i.icon.discussions:before {
    content: "\e901";
    font-family: "boaicon" !important; }

.user-menu-public {
  padding-top: 2px; }
  .user-menu-public .top-menu {
    padding: 0px !important;
    padding-top: 10px !important;
    border-bottom: none;
    line-height: 11px !important;
    margin-right: 6px;
    font-size: 16px !important;
    line-height: 11px;
    font-family: "calibre-regular", sans-serif;
    padding-bottom: 0;
    letter-spacing: 0.2px;
    font-weight: lighter; }
  .user-menu-public .top-menu-active {
    padding: 0px !important;
    border-bottom: none;
    line-height: 11px !important;
    margin-right: 12px;
    font-size: 14px !important;
    line-height: 11px;
    font-family: "calibre-regular", sans-serif;
    padding-bottom: 0;
    letter-spacing: 0.2px; }
  .user-menu-public .item {
    height: 30%;
    margin-top: 55px; }
  .user-menu-public i.icon {
    font-size: 22px; }
    .user-menu-public i.icon.discussions:before {
      content: "\e901";
      font-family: "boaicon" !important; }

.numeric-input {
  text-align: center !important; }

.numeric-input-discount {
  padding-left: 12px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.ui.small.modal {
  left: 30% !important; }
