.my-favorite .category-section .item-content .my-favorite-card {
  padding: 10px 0px; }

.my-favorite .category-section .item-content .add-to-cart .content {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.my-favorite .cart-product-image {
  width: 75px; }

.my-favorite .last-bought {
  font-size: 0.9em;
  font-style: italic;
  color: grey; }

.my-favorite .cart-go-search-link {
  color: green;
  font-size: 0.9375em;
  cursor: pointer; }
  .my-favorite .cart-go-search-link:hover {
    text-decoration: underline; }
