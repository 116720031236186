.new_user_btn {
    width: 150px;
    height: 40px;
    background-color: #66cb23;
    margin-bottom: 10px;
    color: white;
    border: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.new_user_btn:hover {
    background-color: #4CAF50;
}

/* for checkbox */
.user_permission {
    color: #66CB23;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20px;
    height: 20px;
    border: solid 2px #66CB23;
    border-radius: 10px;
}

.user_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 100px;
    padding: 20px;
    border: solid 2px #66CB23;
    border-radius: 10px;
    margin-bottom: 10px;
}

.user_email {
    font-size: 20px;
    font-weight: bold;
}

.user_email:hover {
    color: red;
    cursor: pointer;
}