.asset-detail-body .accordion .active {
  border-bottom: 1px solid lightgray; }

.add-location .button-panel {
  text-align: right;
  padding: 15px 0px 0px 0px; }

.click-To-AttachHose {
  font-size: 65% !important;
  cursor: pointer; }

.add-member {
  font-size: 0.9em;
  cursor: pointer; }
  .add-member:hover {
    color: blue;
    text-decoration: underline; }

@media screen and (max-width: 480px) {
  .asset-Button {
    width: 140%; } }

.HelpText {
  font-size: 60% !important;
  margin-left: 6px !important;
  cursor: pointer; }
