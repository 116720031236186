.image-viewer {
  display: inline-block;
  width: 100%;
  height: 450px;
  background-color: #e0e1e2;
  border: 2px solid #979fa8;
  border-style: dashed;
  cursor: pointer;
  margin: 0 auto;
  overflow: hidden; }

.pg-viewer-wrapper {
  overflow-y: unset !important; }

.pdf-canvas {
  width: 100% !important;
  height: 100% !important;
  margin: auto !important;
  overflow: hidden; }
  .pdf-canvas canvas {
    width: 100% !important;
    height: auto !important;
    margin: auto !important; }

.pdf-viewer-container {
  width: 100% !important;
  height: 100% !important;
  margin: auto !important; }

.pdf-viewer {
  width: 100% !important;
  height: 100% !important;
  margin: auto !important; }

.pg-viewer-wrapper .photo-viewer-container {
  max-height: 100%;
  max-width: 100%;
  width: 100% !important;
  height: auto !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }
