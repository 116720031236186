select {
    width: 300px;
    height: 40px;
    border: 2px solid #cacaca;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    outline: none;
    margin-bottom: 20px;
}

select:focus {
    border-color: #66cb23;
}

option {
    height: 40px;
    font-size: 20px;
    color: black;
}

.model_label {
    display: block;
    font-size: 20px;
    color: #333;
    font-weight: 600;
}