.order-management ::-webkit-scrollbar {
  width: 10px !important; }

.order-management ::-webkit-scrollbar-thumb {
  background: #66cc23; }

.order-management ::-webkit-scrollbar-thumb:hover {
  background: #66cc23; }

.order-management .error input {
  border-color: #cc181e !important; }

.order-management .error {
  border-color: #cc181e !important; }

.order-management .select-error {
  border-color: #cc181e !important; }

.order-management div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 1024px) {
  .order-management .column {
    padding-left: 0px !important; } }

.order-management .ui.labeled.icon.button > .icon,
.order-management .ui.labeled.icon.buttons > .button > .icon {
  color: #66cc23 !important; }

.order-management .order-list-section {
  height: 750px; }
  .order-management .order-list-section hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-left: 10px;
    margin-right: 1rem; }
    @media (min-width: 1025px) {
      .order-management .order-list-section hr {
        margin-left: 20px; } }
  .order-management .order-list-section .order-header {
    height: 45px;
    padding-left: 10px;
    padding-right: 1rem; }
    @media (min-width: 1025px) {
      .order-management .order-list-section .order-header {
        padding-left: 20px; } }
  .order-management .order-list-section .order-status {
    padding-bottom: 10px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 1rem; }
    .order-management .order-list-section .order-status button {
      padding: 8px;
      margin-top: 5px; }
    .order-management .order-list-section .order-status .active-status {
      background-color: #66cc23;
      color: #fff;
      max-width: 292px; }
    @media (min-width: 1025px) {
      .order-management .order-list-section .order-status {
        padding-left: 20px; } }
  .order-management .order-list-section .order-list {
    height: 650px; }
    .order-management .order-list-section .order-list .order-list-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 40px;
      padding-left: 10px;
      padding-right: 1rem; }
      .order-management .order-list-section .order-list .order-list-scroll ::-webkit-scrollbar {
        background: transparent; }
      @media (min-width: 1025px) {
        .order-management .order-list-section .order-list .order-list-scroll {
          padding-left: 20px; } }
      .order-management .order-list-section .order-list .order-list-scroll .active {
        box-shadow: 0 4px 8px 0 #66cc23, 0 4px 8px 0 #66cc23 !important;
        border: 2px solid #66cc23; }
      .order-management .order-list-section .order-list .order-list-scroll .order-card {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 10px;
        padding-right: 10px;
        height: 100%;
        border-radius: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position: relative; }
        .order-management .order-list-section .order-list .order-list-scroll .order-card .content {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          padding-left: 10px !important; }
          @media (max-width: 1024px) {
            .order-management .order-list-section .order-list .order-list-scroll .order-card .content {
              padding-right: 0px; } }
        .order-management .order-list-section .order-list .order-list-scroll .order-card .description {
          font-size: 14px !important; }
        .order-management .order-list-section .order-list .order-list-scroll .order-card .order-state {
          min-height: 117px;
          padding-top: 75px;
          white-space: nowrap; }
          .order-management .order-list-section .order-list .order-list-scroll .order-card .order-state .order-state-text {
            text-align: center;
            font-size: 11px;
            -webkit-transform: rotate(-90deg); }

.order-management .order-form {
  height: 750px; }
  .order-management .order-form .active {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px 0px, 0px 6px 4px 0px #66cc23 !important; }
  @media (max-width: 767px) {
    .order-management .order-form {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .order-management .order-form {
      padding-left: 10px !important; } }
  .order-management .order-form .order-actions {
    height: 45px;
    padding-right: 1rem; }
    .order-management .order-form .order-actions button {
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px; }
      @media (max-width: 1300px) {
        .order-management .order-form .order-actions button {
          font-size: 1vw !important; } }
    .order-management .order-form .order-actions .active-status {
      background-color: #66cc23;
      color: #fff; }
  .order-management .order-form hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-right: 1rem; }
  .order-management .order-form .order-form-content {
    padding-right: 10px;
    height: 750px;
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: scroll; }

.order-management .order-info {
  padding-right: 20px;
  height: 780px;
  overflow-x: hidden;
  overflow-y: scroll; }
  @media (max-width: 767px) {
    .order-management .order-info {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .order-management .order-info {
      padding-left: 10px !important; } }
  .order-management .order-info .active-status {
    background-color: #66cc23;
    color: #fff; }

.order-management .order-card {
  height: 100%;
  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative; }

.edit-state {
  background-color: #ffad00 !important;
  color: #fff !important;
  border-radius: 6px; }
