.Collapsible {
  background-color: white; }

.Collapsible__contentInner {
  padding: 10px;
  border: none;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #66cc23;
  color: white;
  font-size: 15px; }
  .Collapsible__trigger:after {
    cursor: pointer !important;
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black; }

.remove-bom {
  width: 400px !important; }

.hosemaker-modal {
  width: 1380px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: static !important;
  margin-top: 120px !important; }

.other-components-modal {
  width: 900px !important;
  min-height: 448.1px !important;
  max-height: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: static !important;
  margin-top: 120px !important;
  overflow: scroll; }

.results {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 370px; }

.my-assets ::-webkit-scrollbar {
  width: 10px !important; }

.my-assets ::-webkit-scrollbar-thumb {
  background: #66cc23; }

.my-assets ::-webkit-scrollbar-thumb:hover {
  background: #66cc23; }

.my-assets .error input {
  border-color: #cc181e !important; }

.my-assets .error {
  border-color: #cc181e !important; }

.my-assets .select-error {
  border-color: #cc181e !important; }

.my-assets div[disabled] {
  pointer-events: none;
  opacity: 0.7; }

@media (max-width: 1024px) {
  .my-assets .column {
    padding-left: 0px !important; } }

.my-assets .job-list-section {
  height: 750px; }
  .my-assets .job-list-section hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-left: 10px;
    margin-right: 1rem; }
    @media (min-width: 1025px) {
      .my-assets .job-list-section hr {
        margin-left: 20px; } }
  .my-assets .job-list-section .job-header {
    height: 45px;
    padding-left: 10px;
    padding-right: 1rem; }
    @media (min-width: 1025px) {
      .my-assets .job-list-section .job-header {
        padding-left: 20px; } }
  .my-assets .job-list-section .job-status {
    padding-bottom: 10px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 1rem; }
    .my-assets .job-list-section .job-status button {
      padding: 8px;
      margin-top: 5px; }
    .my-assets .job-list-section .job-status .active-status {
      background-color: #66cc23;
      color: #fff; }
    @media (min-width: 1025px) {
      .my-assets .job-list-section .job-status {
        padding-left: 20px; } }
  .my-assets .job-list-section .job-list {
    height: 650px; }
    .my-assets .job-list-section .job-list .job-list-scroll {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding-bottom: 40px;
      padding-left: 10px;
      padding-right: 1rem; }
      .my-assets .job-list-section .job-list .job-list-scroll ::-webkit-scrollbar {
        background: transparent; }
      @media (min-width: 1025px) {
        .my-assets .job-list-section .job-list .job-list-scroll {
          padding-left: 20px; } }
      .my-assets .job-list-section .job-list .job-list-scroll .active {
        box-shadow: 0 4px 8px 0 #66cc23, 0 4px 8px 0 #66cc23 !important; }
      .my-assets .job-list-section .job-list .job-list-scroll .job-card {
        margin-top: 10px;
        border-radius: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        position: relative; }
        .my-assets .job-list-section .job-list .job-list-scroll .job-card .content {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          padding-left: 10px !important; }
          @media (max-width: 1024px) {
            .my-assets .job-list-section .job-list .job-list-scroll .job-card .content {
              padding-right: 0px; } }
        .my-assets .job-list-section .job-list .job-list-scroll .job-card .description {
          font-size: 14px !important; }
        .my-assets .job-list-section .job-list .job-list-scroll .job-card .job-state {
          min-height: 117px;
          padding-top: 75px;
          white-space: nowrap; }
          .my-assets .job-list-section .job-list .job-list-scroll .job-card .job-state .job-state-text {
            text-align: center;
            font-size: 11px;
            -webkit-transform: rotate(-90deg); }

.my-assets .job-form {
  height: 750px; }
  @media (max-width: 767px) {
    .my-assets .job-form {
      margin-top: 20px;
      border-top: 1px solid lightgray;
      padding-top: 20px; } }
  @media (max-width: 1024px) {
    .my-assets .job-form {
      padding-left: 10px !important; } }
  .my-assets .job-form .ui.selection.dropdown .menu > .item {
    font-size: 12px !important; }
  .my-assets .job-form .active-status {
    background-color: #66cc23;
    color: #fff; }
  .my-assets .job-form .job-actions {
    height: 45px;
    padding-right: 1rem; }
    .my-assets .job-form .job-actions button {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px; }
      @media (max-width: 1300px) {
        .my-assets .job-form .job-actions button {
          font-size: 1vw !important; } }
    .my-assets .job-form .job-actions .active-status {
      background-color: #66cc23;
      color: #fff; }
  .my-assets .job-form .job-list-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 40px;
    padding-top: 1rem; }
    .my-assets .job-form .job-list-scroll ::-webkit-scrollbar {
      background: transparent; }
    .my-assets .job-form .job-list-scroll .active {
      box-shadow: 0 4px 8px 0 #66cc23, 0 4px 8px 0 #66cc23 !important; }
    .my-assets .job-form .job-list-scroll .job-card {
      margin-top: 10px;
      border-radius: 0px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      position: relative; }
      .my-assets .job-form .job-list-scroll .job-card .content {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 10px !important; }
        @media (max-width: 1024px) {
          .my-assets .job-form .job-list-scroll .job-card .content {
            padding-right: 0px; } }
      .my-assets .job-form .job-list-scroll .job-card .description {
        font-size: 14px !important; }
      .my-assets .job-form .job-list-scroll .job-card .job-state {
        min-height: 117px;
        padding-top: 75px;
        white-space: nowrap; }
        .my-assets .job-form .job-list-scroll .job-card .job-state .job-state-text {
          text-align: center;
          font-size: 11px;
          -webkit-transform: rotate(-90deg); }
  .my-assets .job-form hr {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin-right: 1rem; }
  .my-assets .job-form .job-form-content {
    padding-right: 10px;
    height: 700px;
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: scroll; }
    .my-assets .job-form .job-form-content .arrow-steps .step {
      font-size: 14px;
      text-align: center;
      color: #0c0d0e;
      cursor: default;
      margin: 0 1px 0 0;
      padding: 10px 0px 10px 0px;
      float: left;
      position: relative;
      background-color: #e3e6ea;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      min-width: 98px; }
    .my-assets .job-form .job-form-content .arrow-steps .step a {
      color: #0c0d0e;
      text-decoration: none;
      cursor: pointer; }
    .my-assets .job-form .job-form-content .arrow-steps .step:after,
    .my-assets .job-form .job-form-content .arrow-steps .step:before {
      content: "";
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
      border-left: 17px solid #e3e6ea;
      z-index: 2; }
    .my-assets .job-form .job-form-content .arrow-steps .step:before {
      right: auto;
      left: 0;
      border-left: 17px solid #fff;
      z-index: 0; }
    .my-assets .job-form .job-form-content .arrow-steps .step:first-child:before {
      border: none; }
    .my-assets .job-form .job-form-content .arrow-steps .step:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .my-assets .job-form .job-form-content .arrow-steps .step span {
      position: relative; }
    .my-assets .job-form .job-form-content *.arrow-steps .step.done span:before {
      opacity: 1;
      content: "";
      position: absolute;
      top: -2px;
      left: -10px;
      font-size: 11px;
      line-height: 21px; }
    .my-assets .job-form .job-form-content .arrow-steps .step.current {
      color: #fff;
      background-color: #66cc23; }
    .my-assets .job-form .job-form-content .arrow-steps .step.current a {
      color: #fff;
      text-decoration: none; }
    .my-assets .job-form .job-form-content .arrow-steps .step.current:after {
      border-left: 17px solid #66cc23; }
    .my-assets .job-form .job-form-content .arrow-steps .step.done {
      color: #0c0d0e;
      background-color: #ced4db; }
    .my-assets .job-form .job-form-content .arrow-steps .step.done a {
      color: #0c0d0e;
      text-decoration: none; }
    .my-assets .job-form .job-form-content .arrow-steps .step.done:after {
      border-left: 17px solid #ced4db; }
    .my-assets .job-form .job-form-content .arrow-steps .step.archived {
      color: #ced4db;
      background-color: #dd4b39; }
    .my-assets .job-form .job-form-content .arrow-steps .step.archived:before {
      border: none !important; }
    .my-assets .job-form .job-form-content .arrow-steps .step.archived a {
      color: #fff;
      text-decoration: none; }
    .my-assets .job-form .job-form-content .arrow-steps .step.archived:after {
      border-left: 17px solid #dd4b39; }

@media (max-width: 767px) {
  .my-assets .job-info {
    margin-top: 20px;
    border-top: 1px solid lightgray;
    padding-top: 20px; } }

@media (max-width: 1024px) {
  .my-assets .job-info {
    padding-left: 10px !important; } }

.my-assets .job-info .active-status {
  background-color: #66cc23;
  color: #fff; }

.my-assets .job-info .job-list .job-list-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 1rem; }
  .my-assets .job-info .job-list .job-list-scroll ::-webkit-scrollbar {
    background: transparent; }
  @media (min-width: 1025px) {
    .my-assets .job-info .job-list .job-list-scroll {
      padding-left: 20px; } }
  .my-assets .job-info .job-list .job-list-scroll .active {
    box-shadow: 0 4px 8px 0 #66cc23, 0 4px 8px 0 #66cc23 !important; }
  .my-assets .job-info .job-list .job-list-scroll .job-card {
    margin-top: 10px;
    border-radius: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative; }
    .my-assets .job-info .job-list .job-list-scroll .job-card .content {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-left: 10px !important; }
      @media (max-width: 1024px) {
        .my-assets .job-info .job-list .job-list-scroll .job-card .content {
          padding-right: 0px; } }
    .my-assets .job-info .job-list .job-list-scroll .job-card .job-state {
      min-height: 117px;
      padding-top: 75px;
      white-space: nowrap; }
      .my-assets .job-info .job-list .job-list-scroll .job-card .job-state .job-state-text {
        text-align: center;
        font-size: 11px;
        -webkit-transform: rotate(-90deg); }

.my-assets .job-card {
  margin-top: 10px;
  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative; }

.image-uploader {
  text-align: center; }

.file-uploader {
  text-align: center; }

.image-upload-button {
  display: inline-block;
  width: 120px;
  height: 120px;
  background-color: #e0e1e2;
  border: 2px solid #979fa8;
  border-style: dashed;
  border-radius: 5%;
  cursor: pointer;
  margin: 0 auto; }

.file-upload-button {
  display: inline-block;
  width: 155px;
  height: 60px;
  background-color: #e0e1e2;
  border: 2px solid #979fa8;
  border-style: dashed;
  border-radius: 5%;
  cursor: pointer;
  margin: 0 auto; }

.file-upload-button2 {
  display: inline-block;
  height: 60px;
  width: 100%;
  background-color: #e0e1e2;
  border: 2px solid #979fa8;
  border-style: dashed;
  border-radius: 10px;
  cursor: pointer; }

.plusIcon {
  font-size: 48px;
  color: #fff;
  line-height: 110px; }

.file-plusIcon {
  font-size: 62px;
  color: #afabab; }

.selected-image {
  margin-top: 20px;
  text-align: center; }

.selected-image img {
  max-width: 100%;
  max-height: 200px; }

.asset-image-container {
  max-width: 100%;
  /* Set the maximum width to 100% of the parent container */
  width: 100%;
  overflow: hidden;
  padding-left: 33px;
  /* Add left padding */
  padding-right: 33px;
  /* Add right padding */
  padding-bottom: 1rem; }

.responsive-image {
  width: 100%;
  height: auto;
  /* Maintain the aspect ratio */
  display: block;
  /* Remove extra spacing added by the inline element */
  object-fit: cover; }

.circle-overlay {
  position: absolute;
  width: 20px;
  /* Set the diameter of the circle */
  height: 20px;
  border-radius: 50%;
  /* Ensures a circular shape */
  background-color: #66cc23;
  /* Adjust the color of the circle */
  cursor: pointer; }
