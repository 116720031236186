.full-screen-div-home {
  position: fixed;
  top: 780px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 10; }

.home .graph-row-container {
  width: 90%;
  margin: 0px auto; }
  .home .graph-row-container .home-welcome-text-container {
    margin-top: 20px;
    margin-bottom: 20px; }

.home .web-calculator-section {
  background: #00001e;
  padding: 50px; }
  .home .web-calculator-section .web-calculator-grid {
    width: 80%;
    margin: 0px auto; }
    .home .web-calculator-section .web-calculator-grid .web-calculator-text {
      color: #fff;
      font-size: 1.25em;
      text-align: center; }
    .home .web-calculator-section .web-calculator-grid .web-calculator-button {
      background: #66cc23;
      color: #fff;
      border-radius: 10px;
      padding: 10px; }
      .home .web-calculator-section .web-calculator-grid .web-calculator-button a {
        margin: 0px auto;
        margin-top: 20px; }

.home .card-box {
  width: 80%;
  margin: 20px auto; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .home .card-box {
      width: 100%; } }
  @media (min-width: 481px) and (max-width: 748px) {
    .home .card-box {
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .home .card-box {
      width: 100%; } }

.enterprise-home .discount-section {
  background: white;
  margin: 30px 0px !important; }
  .enterprise-home .discount-section .discount-panel .value,
  .enterprise-home .discount-section .discount-panel .label {
    text-transform: capitalize; }
  .enterprise-home .discount-section .discount-panel .statistic-value {
    color: green;
    padding-top: 15px; }
  .enterprise-home .discount-section .total-value {
    color: green;
    text-align: center !important; }
  .enterprise-home .discount-section .totalCost {
    color: #21ba45;
    padding: 0px 5px; }

.enterprise-home .own-class {
  color: green !important; }

.retail-home .top-banner .banner {
  background: url("https://s3-ap-southeast-2.amazonaws.com/portal-static-images/retail_banner_desktop.jpg"); }

.retail-home .top-banner .banner {
  width: 100%;
  color: white;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom; }
  .retail-home .top-banner .banner .banner-content-body {
    height: 100%;
    display: flex; }
    .retail-home .top-banner .banner .banner-content-body .content {
      text-align: justify; }
    .retail-home .top-banner .banner .banner-content-body .column {
      margin: auto; }
      @media (min-width: 481px) and (max-width: 748px) {
        .retail-home .top-banner .banner .banner-content-body .column {
          margin: 0px !important;
          width: 100%;
          padding: 28px 17px; } }
      @media screen and (max-width: 480px) {
        .retail-home .top-banner .banner .banner-content-body .column {
          margin: 0px !important;
          width: 100%;
          padding: 28px 17px; } }
  .retail-home .top-banner .banner .header {
    text-align: center; }
  .retail-home .top-banner .banner .body {
    text-align: justify; }

.card-section .card-body {
  display: flex;
  margin-bottom: 2% !important; }

.card-section .column {
  display: flex !important; }
  @media (min-width: 481px) and (max-width: 748px) {
    .card-section .column {
      margin: 0px !important;
      width: 100%;
      padding: 28px 17px; } }
  @media screen and (max-width: 480px) {
    .card-section .column {
      margin: 0px !important;
      width: 100%;
      padding: 28px 17px; } }
  .card-section .column .reveal {
    margin: auto; }

.card-section .visible-content {
  height: 200px;
  width: 200px;
  background: #eee;
  text-align: center;
  font-weight: bolder;
  padding: 82px 0px;
  text-transform: uppercase; }

.card-section .hidden-content {
  height: 200px;
  width: 200px;
  background: #eee;
  cursor: pointer;
  text-align: center;
  padding: 82px 0px; }

.card-section .see-more {
  color: green;
  font-size: 0.9375em;
  cursor: pointer; }

.jobbing-modal {
  margin-top: 3000px !important; }
