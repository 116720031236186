.additional-product-search .search-modal .categories-box .filter-content {
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 0 0 20px #eee;
  margin: 5px;
  border-bottom: 2px solid #00001e;
  color: #111;
  word-wrap: break-word; }
  .additional-product-search .search-modal .categories-box .filter-content:hover {
    background: #eee;
    border-bottom: 2px solid #66cc23; }
  .additional-product-search .search-modal .categories-box .filter-content .filter-content-image {
    padding: 5px; }
    .additional-product-search .search-modal .categories-box .filter-content .filter-content-image img {
      height: 50px;
      width: 50px; }

.additional-product-search .additional-category-grid {
  cursor: pointer; }

.additional-product-info .remove-fitter {
  font-weight: bold;
  color: red;
  font-size: 0.8em;
  cursor: pointer; }
