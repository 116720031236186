.hose-maker-container .spiral-help {
  color: skyblue; }

.hose-maker-container .hose-maker-box {
  border-radius: 10px;
  margin-top: 100px !important; }
  .hose-maker-container .hose-maker-box .fitting-message {
    text-align: center; }
  .hose-maker-container .hose-maker-box .hose-bore {
    padding: 0px !important;
    background: #f8f8f8;
    text-align: center;
    cursor: pointer; }
    .hose-maker-container .hose-maker-box .hose-bore:hover {
      border: dashed 2px #32cd32; }
  .hose-maker-container .hose-maker-box .left-fitting-box {
    background: transparent;
    text-align: center;
    height: 50px;
    cursor: pointer; }
    .hose-maker-container .hose-maker-box .left-fitting-box:hover {
      border: dashed 2px #32cd32; }
  .hose-maker-container .hose-maker-box .bore-image {
    height: 93px !important;
    margin-left: -15px !important;
    width: 104.5% !important;
    z-index: 1;
    position: relative;
    margin-top: -21px; }
  .hose-maker-container .hose-maker-box .select-bore-text {
    padding: 7px;
    height: 57px; }
  .hose-maker-container .hose-maker-box .fitter-image-right {
    z-index: 1000;
    margin-top: -115px !important;
    margin-left: -173px !important;
    width: 233px !important;
    -webkit-transform: rotateX(180deg) scaleX(-1) !important;
    transform: rotateX(180deg) scaleX(-1) !important; }
  .hose-maker-container .hose-maker-box .fitter-image-left {
    z-index: 1000;
    width: 234px;
    margin-top: -105px;
    margin-left: 123px; }
  .hose-maker-container .hose-maker-box .rotateUp {
    -webkit-transform: scaleY(-1) !important;
    transform: scaleY(-1) !important; }

.hose-maker-container .hose-summary-table {
  background: #f8f8f8;
  border: 1px solid #eee;
  margin-top: 140px !important; }

.hose-maker-container .angle-selector-box {
  text-align: center;
  margin-top: 30px; }
  .hose-maker-container .angle-selector-box .gauge {
    margin-top: 10px; }
    .hose-maker-container .angle-selector-box .gauge text {
      display: none; }
  .hose-maker-container .angle-selector-box .angle-help {
    cursor: pointer;
    color: skyblue; }
