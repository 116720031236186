.full_screen_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup_content {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    border: solid 5px #66cb23;
    width: 50%;
    max-width: 400px;
    position: relative;
}

.detail_data_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    /* background-color: ; */
}

.close_btn_assem {
    width: 150px;
    font-size: 20px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(179, 179, 179);
    border: none;
    cursor: pointer;
}

.close_btn_assem:hover {
    background-color: #5f5f5f;
}

.close_btn_container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}